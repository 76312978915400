import React, { Fragment, useEffect } from "react";
import BannerImage from '../../assets/images/about-header.jpg'
import PastWorkshopImage from '../../assets/images/past-workshop.jpg'
import PastWorkshop2Image from '../../assets/images/tvam-initiatives-research.jpg'
import RitusethiWorkshopImage from '../../assets/images/Workshop-on-Indian-Brocade-Traditions-with-Ritu-Sethi.jpg'
import { Helmet } from 'react-helmet';
const Initiatives = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <Fragment>
            <Helmet>
         <title> Initiatives | TVAM Foundation</title>
      <meta name="description" content="Take a look at the textile-focused events we've organized with leading experts in the field, aiming to encourage mindful conversations on conscious fashion. "/>

         </Helmet>
            <img src={BannerImage} className='HomeBannerImg' />
            <div className="container">
                <div className="row customSpacing3">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        {/* <h2 className="text-center display2 marginBottom24px">INITIATIVES OF TVAM FOUNDATION</h2>
                        <p className="paraStyle text-center" >We conceptualize and conduct multi-day ticketed events and webinars focused on textile & fashion content, with complementary workshops and retail, celebrating textiles. With thought leaders like textile historians, revivalists, and weavers coming in to share perspectives with audiences, we engage in interesting and mindful conversations on conscious fashion. Till date, we've had three editions of The Fashion Narrative and engaged with more than 4000 people directly and indirectly.</p> */}
                        <h2 className="text-center display2 marginBottom24px"> OUR INITIATIVES</h2>
                        <p className="paraStyle" >In keeping with some of the Foundation’s mandates to commission research and documentation of textile narratives of Deccan India, and to create awareness, knowledge share and patronage of textiles, we undertake research trips, conduct events and organize workshops. </p> 

                    </div>
                </div>
            </div>

            <div className="bgLightGrey">
                <div className="container">
                    <div className="row customSpacing3">
                        <h2 className="text-center display2">RESEARCH & FIELD TRIPS </h2>
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-6 margMobBtm">
                                <img src={PastWorkshop2Image} className='HomeBannerImg' />
                                </div>
                                <div className="col-md-6">
                                    <br/><br/><br/>
                                <p className="paraStyle">Since the establishment of the Foundation, several field trips have been conducted to connect with weavers, scholars, and custodians of local heritage in the Deccan. Places visited to uncover historical narratives and understand the current landscape include Baroda, Champaner, Aurangabad, Paithan, Ajanta, Ellora, Burhanpur, Maheshwar, Indore, Miraj, Kolhapur, Guledgudda, Ilkal, Yeola, Icchalkaranji, Nasik and Benaras.</p>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className="container">
                    <div className="row customSpacing3">
                        <h2 className="text-center display2">PAST EVENTS</h2>
                        <div className="col-md-12">
                        <p className="paraStyle">We conceptualize and conduct multi-day ticketed events and webinars focused on textile & fashion content, with complementary workshops and retail, celebrating textiles. With thought leaders like textile historians, revivalists, and weavers coming in to share perspectives with audiences, we engage in interesting and mindful conversations on conscious fashion, most of the times, 
                        free of cost to public.</p>

                            {/* <div className="row">
                                <div className="col-md-6 margMobBtm margTopDesk">
                                    <h2 className="text-left innerSubHeading marginBottom24px mobText"><b>Session on Ilkal and Khann with Kubsa</b></h2>
                                    <p className="paraStyle ">In February 2023, we organized a session on Ilkal and Khann Textiles from Northern Karnataka with Geeta Patil, textile designer, revivalist, and founder of Kubsa.</p>
                                    <br/>
                                    <p className="paraStyle">The session was organized in association with our sister concern, Rudraksh who was the curatorial partner at TLS.</p>
                                </div>
                                <div className="col-md-6 youtube-video-container margTopDesk">
                                    {/* <iframe className="mbr-embedded-video" src="https://www.youtube.com/embed/goPG_vBSS0g?rel=0&amp;amp;showinfo=0&amp;autoplay=0&amp;loop=0" width="1280" height="320" frameborder="0" allowfullscreen=""></iframe>
                                    <iframe className="mbr-embedded-video" src="https://www.youtube.com/embed/CLOW8v8dlfM?rel=0&amp;amp;showinfo=0&amp;autoplay=0&amp;loop=0" title="The Fashion Narrative 2019 - TVAM Foundation" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div> */}
                   

                            <div className="row">
                            <div className="col-md-6 youtube-video-container margTopDesk mobileHide">
                                    {/* <iframe className="mbr-embedded-video" src="https://www.youtube.com/embed/goPG_vBSS0g?rel=0&amp;amp;showinfo=0&amp;autoplay=0&amp;loop=0" width="1280" height="320" frameborder="0" allowfullscreen=""></iframe> */}
                                    <iframe className="mbr-embedded-video" src="https://www.youtube.com/embed/elq0GwdGy5M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>

                                <div className="col-md-6 margMobBtm margTopDesk">
                                    <h2 className="text-left innerSubHeading marginBottom24px mobText"><b>Readings on Textiles: From 75 Years of Marg</b></h2>
                                    <p className="paraStyle ">In line with our mandates, we presented the initiatives of The Marg Foundation to Pune for the first time ever, in association with the Bhandarkar Oriental Research Institure (BORI) with three stellar authors and subject experts, Naman Ahuja,
                                     Dr Monisha Ahmed and Mayank Mansingh Kaul, in conversation on Readings on Textiles: From 75 years of Marg</p>
<br/>
                                </div>
                                 <div className="col-md-6 youtube-video-container desktopHide">
                                    {/* <iframe className="mbr-embedded-video" src="https://www.youtube.com/embed/goPG_vBSS0g?rel=0&amp;amp;showinfo=0&amp;autoplay=0&amp;loop=0" width="1280" height="320" frameborder="0" allowfullscreen=""></iframe> */}
                                    <iframe className="mbr-embedded-video" src="https://www.youtube.com/embed/elq0GwdGy5M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                              
                            </div>

                            <div className="col-md-12 minHeight2em mobileHide"></div>

<div className="row">


    <div className="col-md-6 margMobBtm">
        <h2 className="text-left innerSubHeading marginBottom24px mobText"><b>Workshop on Indian Brocade Traditions with Ritu Sethi</b></h2>
        <p className="paraStyle ">

In July 2022, we organized a session in collaboration with designers, Swati and Sunaina, in Pune hosting Ritu Sethi, Founder-Trustee, Craft Revival Trust and Editor, Global Inch. Ms. Sethi spoke on the Brocade Traditions of India wherein she explored the material connections in the changing landscape of brocade weaving. </p>
<br/>
    </div>
     <div className="col-md-6">
     <img src={RitusethiWorkshopImage} className='HomeBannerImg' />
    </div>
  
</div>
                            {/* <div className="col-md-12 minHeight2em mobileHide"></div>
                            <div className="row">
                            <div className="col-md-6 margMobBtm margTopDesk">
                                    <h2 className="text-left innerSubHeading marginBottom24px mobText"><b>Workshop on Indian Brocade Traditions with Ritu Sethi</b></h2>
                                    <p className="paraStyle ">In July 2022, we organized a session in collaboration with designers, Swati and Sunaina, in Pune hosting Ritu Sethi, Founder-Trustee, Craft Revival Trust and Editor, Global Inch. Ms. Sethi spoke on the Brocade Traditions of India wherein she explored the material connections in the</p>
<br/>
                                </div>
                            <div className="col-md-6 youtube-video-container margTopDesk">
                                    {/* <iframe className="mbr-embedded-video" src="https://www.youtube.com/embed/goPG_vBSS0g?rel=0&amp;amp;showinfo=0&amp;autoplay=0&amp;loop=0" width="1280" height="320" frameborder="0" allowfullscreen=""></iframe> 
                                    <iframe className="mbr-embedded-video" src="https://www.youtube.com/embed/CLOW8v8dlfM?rel=0&amp;amp;showinfo=0&amp;autoplay=0&amp;loop=0" title="The Fashion Narrative 2019 - TVAM Foundation" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>

                               
                              
                            </div> */}
                            <div className="col-md-12 minHeight2em mobileHide"></div>

                            <div className="row">
                            <div className="col-md-6 youtube-video-container margTopDesk mobileHide">
                                    {/* <iframe className="mbr-embedded-video" src="https://www.youtube.com/embed/goPG_vBSS0g?rel=0&amp;amp;showinfo=0&amp;autoplay=0&amp;loop=0" width="1280" height="320" frameborder="0" allowfullscreen=""></iframe> */}
                                    <iframe className="mbr-embedded-video" src="https://www.youtube.com/embed/CLOW8v8dlfM?rel=0&amp;amp;showinfo=0&amp;autoplay=0&amp;loop=0" title="The Fashion Narrative 2019 - TVAM Foundation" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            <div className="col-md-6 margMobBtm margTopDesk">
                                    <h2 className="text-left innerSubHeading marginBottom24px mobText"><b>The Fashion Narrative 2019</b></h2>
                                    <p className="paraStyle ">August 9th and 10th were envisioned as days where we conducted workshops, panel discussions,content plenaries and retail. The guests and audience comprised clients of Rudraksh, social influencers 
                                    from different fields, students, faculties and interest groups.</p>
                                    <p className="paraStyle ">The Rudraksh retail hosted weavers, artisans participating by invitation only. 
                                    This was our way of celebrating the Independence week.</p>
                                    <p className="paraStyle ">Workshops were focused around lifestyle topics, to bring in interesting learning opportunities for audiences.</p>
<br/>
                                </div>
                                <div className="col-md-6 youtube-video-container margTopDesk desktopHide">
                                    {/* <iframe className="mbr-embedded-video" src="https://www.youtube.com/embed/goPG_vBSS0g?rel=0&amp;amp;showinfo=0&amp;autoplay=0&amp;loop=0" width="1280" height="320" frameborder="0" allowfullscreen=""></iframe> */}
                                    <iframe className="mbr-embedded-video" src="https://www.youtube.com/embed/CLOW8v8dlfM?rel=0&amp;amp;showinfo=0&amp;autoplay=0&amp;loop=0" title="The Fashion Narrative 2019 - TVAM Foundation" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                                
                           

                               
                              
                            </div>
                            <div className="col-md-12 minHeight2em mobileHide"></div>

                            <div className="row">
                               
                            <div className="col-md-6 margMobBtm">
                                    <h2 className="innerSubHeading marginBottom24px  mobTextLeft mobMarginTop2"><b>The Fashion Narrative 2017</b></h2>
                                    <p className="paraStyle  mobTextLeft">Celebrating the sentiment of the Independence month, we dedicated this event as homage to our grassroots.</p>
                                    <p className="paraStyle  mobTextLeft">Wtih dialogues with royalty, textile historians, revivalists and weavers, from Aug 10-12, 2017,  The Fashion Narrative was the first in the city of Pune to capture conscious fashion, and communicate what it means.</p>
                                    <p className="paraStyle  mobTextLeft">Over 3 days, audiences participatedin workshops and panel discussions or indulged in textile retail and buying from weavers.</p>
                                </div>
                                
                               
                                <div className="col-md-6 youtube-video-container">
                                    {/* <iframe className="mbr-embedded-video" src="https://www.youtube.com/embed/ae1R2fNp048?rel=0&amp;amp;showinfo=0&amp;autoplay=0&amp;loop=0" width="1280" height="320" frameborder="0" allowfullscreen=""></iframe> */}
                                    <iframe className="mbr-embedded-video" src="https://www.youtube.com/embed/jigjfr4dBHQ?rel=0&amp;amp;showinfo=0&amp;autoplay=0&amp;loop=0" title="The Fashion Narrative 2017 - TVAM Foundation" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bgLightGrey">
            <div className="container">
                <div className="row customSpacing3">
                    <h2 className="text-center display2">PAST WORKSHOPS</h2>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-7">
                                <h2 className="innerSubHeading marginBottom24px text-left"><b>WORKSHOP WITH PRAGATI MATHUR AND DHAAGÉ</b></h2>
                                <p className="paraStyle">Under the aegis of TVAM Foundation, we organized a two-day workshop with Pragati Mathur, a renowned weaver, textile designer, experimental weaving artist, and a revivalist.</p>
                                <p className="paraStyle">Dhaagé, Pragati's brainchild, handmakes one of a kind, cutting-edge textiles that amalgamate weaving, design and art with a core ethic that stems from slow processes and minimal consumption.</p>
                            </div>
                            <div className="col-md-5">
                            <img src={PastWorkshopImage} className='HomeBannerImg' />
                            </div>
                        </div>
                        <div className="col-md-12 minHeight2em mobileHide"></div>
                        
                    </div>

                </div>
            </div>
            </div>
        </Fragment>
    )
}
export default Initiatives