import React, { Fragment, useEffect } from "react";
import BannerImage from '../../assets/images/banner-inner.jpg'
const Terms = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <Fragment>
            <img src={BannerImage} className='HomeBannerImg' />
            <div className="container">
                <div className="row customSpacing3">
                    <h2 className="text-center display2">WEBSITE TERMS OF USE</h2>
                    <div className="col-md-12">
                        <div className="wpb_column vc_column_container vc_col-sm-12"><div className="vc_column-inner "><div className="wpb_wrapper">
                            <div className="wpb_text_column wpb_content_element ">
                                <div className="wpb_wrapper">
                                    <ol>
                                        <li><strong> Website. </strong>This Website is owned, managed and operated by <strong>The Vishwas and Anuradha Memorial&nbsp;Foundation</strong> (‘Trust’), a public trust registered under the Bombay Public Trusts Act, 1950, having its registered office at 10 &amp; 11, Conifer Apartments, Off D P Road, Aundh, Pune 411007 and its registration no E – 8454/P.</li>
                                        <li><strong> Purpose of the Website.</strong> This website is a platform available to the general public (‘User’) to get information on the Trust, the services provided by the Trust, the terms and conditions that govern these services and to interact with the Trust through the forums available on the Website.</li>
                                        <li><strong>Acceptance: </strong></li>
                                    </ol>
                                    <p>(a)User is advised to read this Term of Use carefully before accessing, registering or using any of the Services. By accessing, registering or using any of the Services, the User is deemed to be bound by these terms.</p>
                                    <p>(b) Registering, availing any specific Services or participating in any forums available on the Website shall be subject to such further terms and conditions as may be provided by the Trust from time to time on the Website or otherwise.</p>
                                    <ol start="4">
                                        <li><strong> Information on the Website. </strong></li>
                                    </ol>
                                    <p>(a)<strong>&nbsp;</strong>Trust makes no representation that the Information in the Website is appropriate or available for use in places other than India.</p>
                                    <p>(b) User may note that access to the Website from territories where the Information is illegal is prohibited. Those who choose to access this Website from places other than India do so on their own initiative and are responsible for compliance with laws of Indian and the respective applicable local laws.</p>
                                    <p>(c)&nbsp; User may not use or export the Information in violation of Indian laws and regulations. User must ensure that the User is fully comply with the laws of the country from where the User access the Website.</p>
                                    <ol start="5">
                                        <li><strong> Terms of Use of the Website. </strong></li>
                                    </ol>
                                    <p><strong>5.1&nbsp; Information. </strong></p>
                                    <p><strong>&nbsp;</strong>(a) Information shall mean to include all content on the Website including without limitation trademarks, data, images, graphics, logos, icons, guides, news articles, photographs, images, audio clips, video clips, trademarks, service marks and the like, e-mail, messages posted by the User in a chat room, discussion forum or otherwise. User agrees to use the Website and the Information on the Website at his/her own risk.</p>
                                    <p>(b) The Information on the Website is provided on ‘AS IS’ basis and to the fullest extent permitted by law, is provided without warranties of any kind, either express or implied. Without limiting the foregoing, the Trust does not make any warranties of fitness for a particular purpose, title, merchantability, completeness, availability, security, compatibility or non-infringement; or that&nbsp;the Website will be uninterrupted, free of viruses and other harmful components, accurate, error free or reliable.</p>
                                    <p><strong>5.2&nbsp;&nbsp;</strong><strong>Registration. </strong></p>
                                    <p>(a) In consideration of accessing the Website and registering with the Trust, the User represents that the User is of legal age to form a binding contract and is not a person barred from receiving services under the laws of India or other applicable jurisdiction and will only use the Website to make legitimate purchases for the User or for another person for whom the User is legally authorized to act.</p>
                                    <p>(b) User shall be solely responsible to maintain the confidentiality of the password which, together with the login identification (as may be applicable in accordance with the Service selected), allows the User to access the Service. Login identification and password, together with any mobile number or other contact information the User provide, form the User’s (‘Registration Information’). User accepts responsibility for all activities that occur through the User’s account or password. User agrees that the Trust shall not be liable for any unauthorized use or access.</p>
                                    <p>(c) User shall provide true, accurate, current and complete information and undertake to update the Trust if any change in the Registration Information promptly and keep it up-to-date and accurate at all times, as it has a direct bearing on the provision of Services and ancillary services by or through Trust. Additional terms and conditions may apply to Users purchase of services that is selected. Please read these additional terms and conditions carefully.</p>
                                    <p><strong>5.3 Intellectual Property Rights. </strong></p>
                                    <p>(a)<strong>&nbsp;</strong>‘TVAM’ trademark and the logo cannot be used without the prior written approval of the Trust. &nbsp;All the Information displayed, transmitted or carried by the Website are protected by copyright, trademarks and other intellectual property laws.</p>
                                    <p>(b) The Information is owned by the Trust, its affiliates or third-party licensors and neither of them grant any express or implied right to the User. User agrees to abide by all copyright notices and restrictions that are applicable to any Information and not to alter the Information in any way.</p>
                                    <p>(c) User further agrees not to transfer the Information to any other person or do any act that shall result in unauthorized copying of the Information.</p>
                                    <p><strong>&nbsp;</strong><strong>5.4 Hyperlinks.</strong></p>
                                    <p>(a) Website may provide hyperlinks to other internet sites including of co-branders, affiliates, or business partners. These sites are external to the Trust and by visiting these, the User is outside the Website. Since the Trust has no control over such sites and resources, the User acknowledges and agrees that the Trust is not responsible for the availability of such external websites or resources or servers.</p>
                                    <p>(b) Neither the Website nor the Trust endorses in any way nor offers any judgement or warranty and accepts no responsibility or liability for the authenticity, availability of any of the goods or services or for any damage, loss or harm, direct or consequential or any violation of local or international laws that may be incurred by your visiting and transaction on these sites.</p>
                                    <p>(c) User further acknowledge and agree that the Trust shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such website or resource. The Trust reserves the right to terminate any link or linking program at any time. Trust does not endorse companies or products to which it provides web links and reserves the right to note as such on its website. If the User decides to access any of the third-party sites linked to the Website, the User may do so at his/her own risk and choice.</p>
                                    <p><strong>5.5 Advertisements and third-party content. </strong></p>
                                    <p>(a)<strong>&nbsp;</strong>Website may contain content and advertisements of third party. Trust expressly disclaims any liability arising out of such third-party content and advertisements including usage of these products advertised on the Website. Trust will have no liability related to the content of any Information posted by third parties, whether or not arising under the laws of copyright, libel, privacy, obscenity, or otherwise.</p>
                                    <p>(b)&nbsp; All liability is excluded to the extent permitted by law including any implied terms. Trust neither takes any responsibility for the content or the advertisements, nor does it take any responsibility for the products or services provided by advertisers.</p>
                                    <p>(c) Any dealings that the User may have with the advertisers found while using the Services are between the User and the advertiser, and the User agrees that the Trust is not liable for any loss or claim that User may have against a content originator or the advertiser. While using this website for information purposes or otherwise, Users acknowledge and accept the above disclaimer.</p>
                                    <p><strong>5.6 Use of Information or the Website. </strong></p>
                                    <p><strong>&nbsp;</strong>(a)<strong>&nbsp;</strong>The User agrees that User will not misuse the Information that the User may access as part of the Service, including without limitation, download the content by illegal/non-permitted means, or infringe any of the intellectual property rights owned by the Trust, its affiliates or third-party licensors.</p>
                                    <p>(b) In case the Website allows the User to download or stream any of the Information, the User agree that the User will not use, sell, transfer or transmit the Information to any person or upload the Content on any other website, webpage or software, which may violate/cause damages or injuries to any rights of Trust or its affiliates or any third party including privacy rights, publicity rights, and intellectual property rights.</p>
                                    <p><strong>&nbsp;</strong><strong>5.7 Malicious Software and device. </strong></p>
                                    <p><strong>&nbsp;</strong>(a)<strong>&nbsp;</strong>User acknowledges and agree not to either directly or through the use of any device, software, internet site, web-based service, or other means remove, alter, bypass, avoid, interfere with, or circumvent any copyright, trademark, or other proprietary notices marked on the Information or any digital rights management mechanism, device, or other content protection or access control measure associated with the Information including geo-filtering mechanisms.</p>
                                    <p>(b) User agrees not to either directly or through the use of any device, software, internet site, web-based service, or other means copy, download, capture, reproduce, duplicate, archive, distribute, upload, publish, modify, translate, broadcast, perform, display, sell, transmit or retransmit the Information or create any derivative work and/or material based on the Information.</p>
                                    <p><strong>&nbsp;</strong><strong>5.8 Interruptions, technical and operational Malfunction. </strong></p>
                                    <p><strong>&nbsp;</strong>(a)<strong>&nbsp;</strong>Trust reserves the right to temporarily suspend access to the whole or any part of the Website for any technical/operational reason. Trust may, but is not obligated to, give the User notice of any such interruption. Trust will restore access to the Website as soon as reasonably practicable after temporary suspension.</p>
                                    <p>(b) User agree that the Trust shall not be responsible for any problems or technical malfunction of any computer online systems, servers, computer equipment or software due to technical problems or traffic congestion on the Website or due to use of any of the Services, including any injury or damage to the User(s)’ or to any person(s)’ computer related to or resulting from participation or downloading materials on the Website.</p>
                                    <p><strong>5.9 Force majeure event.</strong> User agrees that the Trust shall be under no liability whatsoever to the User on non-availability of the Website or any portion thereof occasioned by Act of God or a force majeure event such as war, disease, revolution, riot, civil commotion, strike, lockout, flood, fire, satellite failure, failure of any public utility,&nbsp;man-made disaster, satellite failure&nbsp;or any other cause whatsoever beyond the control of Trust.</p>
                                    <p><strong>&nbsp;</strong><strong>5.10 Privacy Policy.</strong> Trust reserves the right to use the Information provided by the User in accordance with the Privacy Policy as set out on the Website. User are requested to read and completely understand the Privacy Policy before sharing any information with the Trust.</p>
                                    <p><strong>5.11 Developments. </strong></p>
                                    <p><strong>&nbsp;</strong>(a)<strong>&nbsp;&nbsp;</strong>The development of the Website is a continuous and ongoing process. Trust reserves the right to change, discontinue or redo the Website and/or the Information on Website and/or any Services availed through the Website. Trust disclaims any and all liability and/or responsibility for loss of any information or data, or any consequence resulting from such development or operation of the Website.</p>
                                    <p>(b) Trust does not assume or purport to assume any responsibility or liability to the User for any failure or inability to access or use the Website or any Services availed through the Website. Information including published information, statistics or data available on the Website may not be current or up to date.</p>
                                    <ol start="6">
                                        <li><strong> Limitation of Liability.</strong></li>
                                    </ol>
                                    <p>(a)&nbsp; Neither the Trust nor its Trustees nor its officers nor its employees nor its affiliates nor its agents nor its contractors nor its licensors shall be liable for damages including without limitation damages for loss of profit arising in contract, tort or otherwise from the use of or inability to use this Website, including but not limited to direct, indirect, incidental, special, punitive or consequential damages, from the User’s use or inability to use the Website or any content or information or from any action taken (or refrained from being taken) as a result of using the Website or any content or Information, regardless of whether the Trust has been notified of such damages.</p>
                                    <p>(b)&nbsp; Trust does not assume any liability or responsibility for the accuracy, completeness, or usefulness of any Information, apparatus, product, or process disclosed nor represents that its use would not infringe privately owned rights.</p>
                                    <ol start="7">
                                        <li><strong> Indemnity. </strong></li>
                                    </ol>
                                    <p>7.1<strong>&nbsp;</strong>User agrees to defend, indemnify and hold harmless the Trust, its affiliates, officers, directors, employees and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney’s fees) arising from:</p>
                                    <p>(i) The User’s use of and access to the Website including the Services;</p>
                                    <p>(ii) User’s violation of any term of this Terms of Use;</p>
                                    <p>(iii) User’s violation of any third party right, including without limitation any copyright, property, or privacy right; and</p>
                                    <p>(iv)&nbsp; User’s violation of any applicable laws.</p>
                                    <p>7.2&nbsp; This indemnification obligation will survive the expiry or termination of this Term of Use and the User’s access and/or use of the Website and/or service.</p>
                                    <ol start="8">
                                        <li><strong> Termination of Access and/or Services. </strong></li>
                                    </ol>
                                    <p>8.1<strong>&nbsp;</strong>If the User violates any of this Term of Use, the Trust may deny the User access to the Website (or part thereof) and also disable any other user name and password associated with the User. This is in addition to any other remedies that the Trust may have under law or equity including that of termination of the registration for the services that the User may avail from the Trust.</p>
                                    <p><strong>&nbsp;</strong>8.2 Trust reserves the right to suspend or terminate the User’s access and use of this Website or registration, as the case may be, at any time, without assigning any reason whatsoever, in addition to the reasons stated in this Term of Use.</p>
                                    <p>8.3 Trust may exercise this right of termination, with or without giving notice to the User. User agrees that the Trust shall not be liable to the User or to any third party for any suspension or discontinuance of the Website or the service availed through the Website.</p>
                                    <ol start="9">
                                        <li><strong> Severability</strong>. If any provision of this Terms of Use is held invalid, void, or unenforceable, then that provision shall be considered severable from the remaining provisions, and the remaining provisions given full force and effect.</li>
                                        <li><strong> Governing Law and Jurisdiction.</strong> This Terms of Use is governed by the laws of India. If any of the parties wish to seek legal recourse, they may do so by solely using the courts of law in Pune.</li>
                                        <li><strong> Updates. </strong>Trust reserves the right to change this this Term of Use from time to time and the User should check these regularly. User’s access and use of the Website will be deemed an acceptance of this Term of Use existing at that time.</li>
                                        <li><strong>Queries: </strong>If the User has questions, concerns, or suggestions regarding our Website, this Terms of Use, User may contact us at __________________.</li>
                                    </ol>

                                </div>
                            </div> </div></div></div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default Terms