    import React, { Fragment, useEffect } from "react";
    import Carousel from 'react-bootstrap/Carousel'
    import BannerImage from '../../assets/images/library-at-tvam.png'
    import SharadImage from '../../assets/images/Sharad-Munde.jpg'


    import { Helmet } from 'react-helmet';
    const Library = () => {
        useEffect(() => {
            window.scrollTo(0, 0)
        }, [])
        return (
            <Fragment>
                <Helmet>
            <title>Library at TVAM | TVAM Foundation</title>
        <meta name="description" content="TVAM Foundation is committed to researching, celebrating, and informing the general public of the rich textile traditions of the Deccan and its narratives. "/>

            </Helmet>
                <img src={BannerImage} className='HomeBannerImg' />
            
            
                    <div className="container">
                        <div className="row customSpacing3">
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <div className="row">
                                
                                    <div className="col-md-12">
                                        <p className="paraStyle">At TVAM Foundation, we are home to over 1000 books and many more in our digital archive.
                                        The book titles cover varied topics, including Deccan India, Textiles of India, history, geopolitics, philosophy, public policy, biographies, literature, and more.
</p>
                                        <p className="paraStyle">Contact reachus@tvamfoundation to make an appointment during our office hours to supplement your research objectives. Our library is open to public access 
                                        by prior appointments.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        <div className="bgLightGrey">
                <div className="container">
                    <div className="row customSpacing3">
                        <h2 className="text-center display2 marginBottom24px">ACKNOWLEDGEMENT & GRATITUDE</h2>
                    
                    
                <div className="container">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-3">
                            <img src={SharadImage} className="HomeBannerImg" />
                            </div>
                            <div className="col-md-9">
                            <p className="paraStyle">TVAM Foundation received a generous donation of approximately 650 book titles from the kin of Shri Sharad O. Mande, in areas of varied research interest as water management, geopolitics, literature, architecture, philosophy, history, etc., making a
                            valuable addition to our extensive library of research books.</p>
                            <p className="paraStyle">Mr. Sharad D. Mande (10th Dec 1942-21st Aug 2022), dedicated a majority of his life
                            working in the water and environment sector. He worked on major water supply schemes around the state of Maharashtra. After opting for voluntary retirement from government services, he started his firm S.D. Mande & Associates Consulting Engineers Pune, and continued to work in the water sector. </p>
                            </div>
                            <div className="col-md-12">
                            <p className="paraStyle">He was the Secretary of IWWA, Pune for 25 years. Secretary deseral of IWWA, Mumbai for 4 years, and Secretary of
                            "Upper Bhima Water Partnership" and "Reuse of City Effluents" for GWP-SASTAC. He was also a lifelong member of
                            NRIPO, AESA, FEED, and Bhandarkar Oriental Research Institute. In 2017, he presented a paper on Burhanpur-A
                            City of Hydraulic Wonders at the International Conference on "Karez on Cultural Borders" held at Bidar.</p>
                            <p className="paraStyle">We are grateful to Mr. Mande's family for their generous donation to the TVAM Foundation library open to public.</p>
                            </div>
                        
                        </div>
                    </div>

                </div>
                        

                    </div>
                </div>
                </div>
                
            </Fragment>
        )
    }
    export default Library