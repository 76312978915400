import React, { Fragment, useEffect ,useState} from "react";
import { Helmet } from 'react-helmet';
import Eventbanner from '../../assets/images/eventbanner.png'
import About2Image from '../../assets/images/readings-on-textile.png'
import namanahujaImage from '../../assets/images/naman-ahuja.png'
import monishaahmedImage from '../../assets/images/monisha-ahmed.png'
import mayankkaulImage from '../../assets/images/mayank-mansingh-kaul.png'
import PartnerImage1 from '../../assets/images/Institute Poona 2.png'
import PartnerImage2 from '../../assets/images/Marg Foundation.png'
import LogoImage from '../../assets/images/tvam-foundation-logo-b.png'
const Event = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

      const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [popupShow, setPopupShow] = useState(false);
    const handleClosePopup = () => setPopupShow(false);
    const handleShowPopup = () => setPopupShow(true);
    return (
        <Fragment>
             <Helmet>
         <title> Conversations on Readings on Textiles | TVAM Foundation x BORI</title>
         <meta property="og:image" content="${require(`../../assets/images/eventbanner.png`)}" /> 
      <meta name="description" content="TVAM Foundation and Bhandarkar Oriental Research Institute (BORI) present a conversation on Readings on Textiles: From 75 years of MARG. Register Now. "/>

         </Helmet>
            <img src={Eventbanner} className='HomeBannerImg' />
            <div className="container">
                <div className="row customSpacing4">
                    
               
                    <div className="col-md-12">
                        <div className="row">
                           
                                <p className="paraStyle justifyMobtxt">TVAM Foundation, in association with the Bhandarkar Oriental Research Institute (BORI), presents three
                                    stellar authors and subject experts, Naman Ahuja, Dr Monisha Ahmed, and Mayank Mansingh Kaul, in
                                    conversation on <b>Readings on Textiles: From 75 years of MARG.</b> The Readings on Textiles brings
                                    together writings on Indian textiles from the magazine’s remarkable archives. Critically prefaced and
                                    edited, the issue showcases expert studies of how textiles are threaded through Indian history and
                                    society.</p>
                                    {/*<p>We are happy to present the initiatives of The Marg Foundation to Pune for the first time ever.</p>
                                    <p className="paraStyle justifyMobtxt">Limited Seats are available through registration on the link below, on a first come first serve basis.</p>*/}
                            
                                <div className="text-center CustomPadding2"><a  target="_blank" className="aUrl" onClick={handleShowPopup} style={{cursor:"pointer"}}>Registrations are currently closed for this event. To add yourself to the waitlist, please connect with Shivangi on 8007903355</a></div>
                                <div className="enquireBtn" onClick={handleShowPopup}>Registration Closed</div>
                                
                                <div className={popupShow?"enquireNowFormCont enableEnquire ":"enquireNowFormCont"}>
                <div className="closeBtn" onClick={handleClosePopup}>X</div>
                <div className="formtitle">
                    <h4 className="titleTxt">REGISTRATIONS CLOSED</h4>
                </div>
                
                <div className="whitepatch"></div>

                <div>
                    {/*<iframe src="https://share.hsforms.com/193Q787zfReaSO1ywy8Dkggdxtv4" width="100%" height="800px"></iframe>*/}
                    <p><b>Registrations for this event are currently closed.</b></p>
                    <p><b>To add yourself to the waitlist, please connect with Shivangi on <a href="tel:8007903355">8007903355</a></b></p>
                </div>
                
               

            </div>
                        </div>
                    </div>
                </div>
            </div>
           
            
            <div className="bgLightGrey">
                <div className="container">
                    <div className="row customSpacing4">
                    <h2 className="text-center display2 marginBottom24px">PROGRAM GUIDE</h2>
                    <div className="col-md-5">
                            <img src={About2Image} className='HomeBannerImg' />
                        </div>
                        <div className="col-md-7">
                            
                            <p className="paraStyle">The event will be held at the  <b>Navalmal Firodia Auditorium, BORI, </b> ILS Law College Road, Pune on <b>22.12.2022 , 5 pm - 7 pm.</b></p>
                            <br/>
                            <p className="paraStyle"> <b>OPENING REMARKS AND INTRODUCTION </b> <br/>
                            Mrs.  Rasika Wakalkar, Founder and Trustee, TVAM Foundation <br/>
                            Mr. Bhupal Patwardhan, EB Chairman, BORI</p>
                           
                            <p className="paraStyle"><b>75 YEARS OF MARG: READINGS ON TEXTILES &amp; READINGS ON THE TEMPLE</b><br/>
                             Mr. Naman P. Ahuja, Editor, Marg</p>
                            <p className="paraStyle"><b>READINGS ON TEXTILES: INSIGHTS &amp; CONVERSATION</b><br/>
                            Mr. Naman P. Ahuja, Dr. Monisha Ahmed and Mr. Mayank Mansingh Kaul</p>
                            <p className="paraStyle"><b>VOTE OF THANKS AND CLOSING REMARKS </b><br/> 
                            Mrs. Rasika Wakalkar, Founder and Trustee, TVAM Foundation</p>
                        </div>
                        <div className="text-center CustomPadding2"><a  target="_blank" className="aUrl" onClick={handleShowPopup} style={{cursor:"pointer"}}>REGISTRATIONS CLOSED</a></div>
                    </div>
                </div>
            </div>
            
           
                <div className="container">
                    <div className="row customSpacing4">
                        <h2 className="text-center display2 marginBottom24px">PANEL OF SPEAKERS</h2>

                        <div className="col-md-1"></div>
                        <div className="col-md-10 boxStyle" style={{backgroundColor:"#f6f6f6"}}>
                            <div className="row"> 
                                <div className="col-md-2 text-center">
                                    <img src={namanahujaImage} className='HomeBannerImg mobimg50' />
                                </div>
                                <div className="col-md-10">
                                    <p className="paraStyle justifyMobtxt"><b>Naman P. Ahuja</b> is a curator and Professor at Jawaharlal Nehru University, New Delhi and the Editor of
                                    Marg Publications. His studies on Indian art have explored the aesthetics of Indian visual culture,
                                    iconography and transculturalism in antiquity, as well as the legacy of the Arts and Crafts Movement in
                                    the modern period.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-10 boxStyle" style={{backgroundColor:"#f6f6f6"}}>
                            <div className="row">
                                <div className="col-md-2 text-center">
                                    <img src={monishaahmedImage} className='HomeBannerImg mobimg50' />
                                </div>
                                <div className="col-md-10">
                                    <p className="paraStyle justifyMobtxt"><b> Monisha Ahmed</b> is an independent researcher, writer and curator whose work focuses on art practices
                                and material culture in Ladakh. From 2010 to 2016, she was Associate Editor of Marg magazine. She is
                                Co-founder and Executive Director of Ladakh Arts and Media Organisation, Leh.</p>
                                  </div>
                            </div>
                        </div>


                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-10 boxStyle" style={{backgroundColor:"#f6f6f6"}}>
                            <div className="row">
                                <div className="col-md-2 text-center">
                                    <img src={mayankkaulImage} className='HomeBannerImg mobimg50' />
                                </div>
                                <div className="col-md-10">
                                    <p className="paraStyle justifyMobtxt"><b> Mayank Mansingh Kaul</b> is a New Delhi-based writer and curator with a focus on post-independence
                                    histories of textiles, design and fashion in India. He is Consulting Editor, Architectural Digest India, and a
                                    member of the Advisory Council on Arts &amp; Culture (South Asia) at the Asia Society.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div>
            


            <div className="bgLightGrey">
                <div className="container">
                    <div className="row customSpacing4">
                        <h2 className="text-center display2 marginBottom24px">OUR PARTNER INSTITUTIONS FOR THE EVENT</h2>

                        
                        <div className="col-md-6 boxStyle2 col-xs-12">
                            <div className="row">
                            <div className="col-md-1"></div>
                                <div className="col-md-10 text-center">
                                    <img src={PartnerImage1} className='HomeBannerImg partnerMarginBtm'  />
                                </div>
                                <div className="col-md-1 "></div>
                                <div className="col-md-12">
                                    <p className="paraStyle justifyMobtxt">The <b><a href="https://bori.ac.in/" target="_blank" style={{color:"#0a58ca",textDecoration:"underline"}}>Bhandarkar Oriental Research Institute</a> </b> Pune, concerns itself with research activity in the field of
                                    Orientology with a vision to enlighten the world about the all-comprehensive knowledge generated in
                                    South Asia, especially in India. The Institute has one of the largest collections of rare books and
                                    manuscripts collected over 90 years, covering practically every aspect of Orientology.
                                    </p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-6 boxStyle2 col-xs-12">
                            <div className="row">
                            <div className="col-md-1"></div>
                                <div className="col-md-10 text-center">
                                    <img src={PartnerImage2} className='HomeBannerImg partnerMarginBtm ' />
                                </div>
                                <div className="col-md-1"></div>
                               
                                <div className="col-md-12">
                                    <p className="paraStyle justifyMobtxt">In 1946, novelist and activist Mulk Raj Anand, along with a group of 14 artists, art historians and
                                    architects, founded <b><a href="https://marg-art.org/" target="_blank" style={{color:"#0a58ca",textDecoration:"underline"}}>MARG </a></b>(Modern Architectural Research Group) in India on the threshold of
                                    Independence. Over the last 75 years, their magazine and books have been a forum for pioneering
                                    research in Indian art and are acclaimed for their standards of production and editorial content.</p>
                                </div>
                            </div>
                        </div>


                        
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default Event