import React, { Fragment, useEffect } from "react";
import Carousel from 'react-bootstrap/Carousel'
import Modal from "react-bootstrap/Modal";
import BannerImage from '../../assets/images/paithani-banner.png'
import publishingImage from '../../assets/images/paithani-image.jpg'
import BookofPaithani from '../../assets/images/book-for-website.png'

import Kathapadarofbanner from '../../assets/images/kathapadar-banner.jpg'

import Kathapadarofgallery from '../../assets/images/kathapadar-gallery.jpg'
import Kathapadarofgallery1 from '../../assets/images/kathapadar-gallery1.jpg'
import Kathapadarofgallery2 from '../../assets/images/kathapadar-gallery2.jpg'
import Kathapadarofgallery4 from '../../assets/images/kathapadar-gallery4.jpg'


import Kathapadarofgallery22 from '../../assets/images/kathapadar-img22.jpg'
import Kathapadarofgallery221 from '../../assets/images/5a.png'
import Kathapadarofgallery222 from '../../assets/images/5b.png'
import Kathapadarofgallery223 from '../../assets/images/5c.png'
import Kathapadarofgallery224 from '../../assets/images/5d.png'
import Kathapadarofgallery225 from '../../assets/images/5e.png'
import Kathapadarofgallery226 from '../../assets/images/5f.png'
import Kathapadarofgallery227 from '../../assets/images/5g.png'





import { Helmet } from 'react-helmet';
const Paithani = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [isOpen,setIsOpen] = React.useState(false);

    const showModal = () => {
        setIsOpen(true);
    };

    const hideModal = () => {
        setIsOpen(false);
    };


    return (
        <Fragment>
            <Helmet>
                <title>Kath Padar Paithani and Beyond | TVAM Foundation</title>
                <meta name="description" content="TVAM Foundation is committed to researching, celebrating, and informing the general public of the rich textile traditions of the Deccan and its narratives. " />

            </Helmet>
            <Modal show={isOpen} onHide={hideModal}>
                <Modal.Body>
                <div className="container">
                    <div className="row customSpacing3">
                        <div className="container">
                        <button className="closeButton" onClick={hideModal}>X</button>
                            <div className="col-md-12">
                            <p className="paraStyle text-center"><b>Pre-Order the Exhibition Catalogue</b></p>

                            <embed src="https://share.hsforms.com/1PlMHr_SMTdiKeLarS2Olqgq8qa1" width="100%" height="500" ></embed>
                            </div>

                        </div>


                    </div>
                </div>
    
                  


                    
                </Modal.Body>
            </Modal>
            
            <img src={Kathapadarofbanner} className='HomeBannerImg' />

       {/*      <div className="container">
                <div className="row customSpacing3">



                    <div className="container">
                        <div className="col-md-12">
                            <p className="text-center paraStyle"><b><a href="/#/registration-form" class="acUrl" style={{ textDecoration: "none" }} target="_blank">Order the Kāth Padar Catalogue for <br/>
INR 2200 inclusive of shipping in India*</a></b></p>
                        </div>

                    </div>


                </div>
    </div>  */}

            <div className="customSpacing3">
                <div className="container">
                    <div className="row marginBottom24px">
                        <div className="container">
                            <div className="col-md-12">
                                <div className="row">


 <div className="col-md-12">
   <p className="paraStyle">In 2023, over a few months, TVAM Foundation undertook an in depth on-field exercise of photographing, documenting and studying the textiles on display and in the archives of Shri Chattrapati Shivaji Maharaj Museum in Satara, Shri Bhavani Museum and Library in Aundh, and the Nagpur Central Museum in Nagpur. The documentation was then donated to the Directorate of Archaeology and Museums as a digital and printed archive. From these three collections, specifically the Paithani objects, were chosen for the Kāth Padar exhibition. </p> 

<p className="paraStyle">Kāth Padar — Paithani & Beyond, the first-ever exhibition in Paithan of historical Paithani textiles, was organised by TVAM Foundation in association with The Directorate of Archaeology and Museums, Maharashtra at Shri Balasaheb Patil Government Museum, Paithan, Maharashtra. Initially scheduled from the 20th of October 2023 - 5th of November 2023, the exhibition was extended twice and thus, closed on 25th of November 2023, highlighting its success.   </p>

<p className="paraStyle">The textiles on display were drawn from the collections of Shri Chattrapati Shivaji Maharaj Museum in Satara, Shri Bhavani Museum and Library in Aundh, and the Nagpur Central Museum in Nagpur, along with private collections. The curation by Mayank Mansingh Kaul comprised sarees, shelas (shawls) and draped headgear.</p>

<p className="paraStyle">Over the course of the exhibition, over 2000 people visited us. The profile of the visitors was found to include textile scholars, educators, students, textile enthusiasts from across the country, and weavers. Visitors travelled from over 20 different locations including Delhi, Coimbatore, Bangalore, Kolkata, Mumbai, Jalna, Yeola, Chattrapati Sambhaji Nagar, Pune and more.  </p>
</div>

                                    <div className="col-md-12 CustomPadding2">
                                        

                                        <Carousel interval={3000} fade>
                <Carousel.Item>
                <img src={Kathapadarofgallery} className="figure" />
                    </Carousel.Item>
                    <Carousel.Item>
                    <img src={Kathapadarofgallery1} className="figure" />
                    </Carousel.Item>
                    <Carousel.Item>
                    <img src={Kathapadarofgallery2} className="figure" />
                    </Carousel.Item>
                    <Carousel.Item>
                    <img src={Kathapadarofgallery4} className="figure" />
                    </Carousel.Item>
                   

                </Carousel>
                               <div class="bgLightGrey">      <p className="figcaption"> <b>Inauguration of the exhibition </b> <br/>

Seen from Left to Right: Mayank Mansingh Kaul, Textile Curator, Kāth Padar; Shaikh Saleem Shaikh Abdul Raheman, Clerk, Shri Balasaheb Patil Government Museum, Paithan; Chetan Wakalkar, Trustee, TVAM Foundation; Rasika Wakalkar, Founder-Trustee, TVAM Foundation; Balaji Wange, Dy Commissioner, Textiles, Govt of Maharashtra; Dr. Tejas Garge, Director, Directorate of Archaeology and Museums, Govt. of Maharashtra; Farogh Mukadam, Additional Collector and JMD, MSSIDC; Amrut Patil, Curator, Shri Balasaheb Patil Government Museum, Paithan </p>
</div>       </div>

                           {/*      <div className="col-md-7">
                                         <p className="paraStyle">The Directorate of Archaeology and Museums, Maharashtra in
                                            association with TVAM Foundation present <b>Kath Padar —
                                                Paithani & Beyond</b>, an exhibition of historical Paithani textiles at
                                            Shri Balasaheb Patil Government Museum, Paithan, Maharasthra.
                                            The textiles on display are drawn from the collections of Shri
                                            Chattrapati Shivaji Maharaj Museum in Satara, Shri Bhavani
                                            Museum and Library in Aundh, and the Nagpur Central Museum
                                            in Nagpur, along with private collections, and comprise sarees,
                                            shelas (shawls) and draped headgear. </p>
                                        <p className="paraStyle">The exhibition is the first to showcase historical samples of the
                                            Paithani in Paithan itself. Paithani from the State Government Museums Archives is one of India's
                                            most
                                            specialised forms of handwoven textiles and Paithan is the
                                            handloom centre which has given the genre its widely known
                                            name. It is curated by <b>Mayank Mansingh Kaul</b> with exhibition
                                            design by <b>Lokus Design</b> and the accompanying catalogue
                                            designed by <b>Reha Sodhi</b>. The Opening Programme includes a
                                            walkthrough of the exhibition; talks by eminent speakers who will
                                            highlight aspects of Paithan’s wider culture beyond textiles as
                                            well, from its history to architecture; interactions with Paithani
                                            weavers; and visits to sites of archaeological interest in the
                                            town. </p>
                                        <p className="paraStyle">
                                            <b>Entry to the exhibition gallery is Free of Charge. The ticket for
                                                an entry to the Paithan Museum costs INR 10. For visitors
                                                from outside Paithan, prior registration is encouraged.</b>
                                        </p>
 

                                    </div>    */}
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>


            <div className="bgLightGrey customSpacing3">
                <div className="container">
                    <div className="row marginBottom24px">
                        <div className="container">
                            <div className="col-md-12">
                                <div className="row">


                                    <div className="col-md-5">
                                        <img src={publishingImage} className="HomeBannerImgg2" />
                                    </div>

                                    <div className="col-md-7">
                                    <h2 className="display2 margintopBottom24px"> THE KĀTH PADAR CATALOGUE</h2>
                                    <p className="paraStyle margintopBottom24px">A catalogue was released on the inaugural day of the exhibition to document the textiles on display. It includes a detailed technical analysis of the objects, along with essays by Mayank Mansingh Kaul, Dr R.S. Morwanchikar, and Dr Tejas Garge, and a foreword by Rasika Wakalkar.</p>

<p className="paraStyle">We thank The Sevak Trust for their generous CSR support in printing of this catalogue.</p>

<div className="customSpacing3">
                        <div className="col-md-12">
                        <p className="paraStyle">*For International orders please contact us at rutuja@tvamfoundation.com </p>
                            <p className="paraStyle margintopBottom24px"><b><a href="https://rzp.io/l/kath-padar-paithani-and-beyond" class="acUrl" style={{ textDecoration: "none" }} target="_blank">Order the Kāth Padar Catalogue for <br/>
INR 2200 inclusive of shipping in India*</a></b></p>


                        </div>

                    </div>

                                    </div>

            
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>


            <div className="customSpacing3">
                <div className="container">
                    <div className="row marginBottom24px">
                        <div className="container">
                            <div className="col-md-12">
                                <div className="row">
                                <h2 className="text-center display2 marginBottom24px">EXHIBITING IN PAITHAN</h2>
                                    <div className="col-md-5">
                                        <iframe width="100%" height="300" src="https://www.youtube.com/embed/5uNpTqE11hY?si=ZX66W4leFIcXHZlW" title="YouTube video player"></iframe>
                                    </div>



                                    <div className="col-md-7">
                                    <p className="paraStyle">Our goal was to not only showcase the lost glory of the Paithani at the textile gallery but also to showcase these historical textiles in the milieu of Paithan, thus taking it beyond just the Paithani. It was important for us to bring more public awareness not only to the Paithani but also the town of Paithan.</p>

<p className="paraStyle">The existence of Paithan can be traced as far back as the 2nd century BC when it became the capital city of the Satavahana empire. Located on the banks of the river Godavari and earlier known as Pratishthāna, Paithan has been the seat of various dynasties. </p>


                                    </div>



                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>


            <div className="bgLightGrey customSpacing3">
                <div className="container">
                    <div className="row marginBottom24px">
                        <div className="container">
                            <div className="col-md-12">
                                <div className="row">




                                    <div className="col-md-7">
                                    <p className="paraStyle">We also had the privilege of being visited by some of the most well-known names in the fields of textile studies and fashion, from textile patrons to collectors to fashion designers and erstwhile royalty, who applauded the initiative and its vision. Our visitors included HH Maharani Damayanti Raje Bhonsle of Satara, HH Babaji Raje Bhonsle of Thanjavur, David Abraham, Rakesh Thakore, Kevin Nigli, Lekha Poddar, Ahalya Matthan, Dr. Monisha Ahmed, Vaishali Shadangule, Arti Kirloskar, Manish Saxena, and faculty members from NID, Pearl Academy, and KCT Coimbatore. </p>

                                    <p className="paraStyle">The programme of the day comprised a guided walkthrough with Mayank, about an hour of interacting with weavers at MSSIDC, and a walkabout in the village to understand the architecture and history and take in the vibe and vibrancy of Paithan.
 </p>






                                    </div>
                                    <div className="col-md-5">

                                        
                                    <Carousel interval={3000} fade>
                <Carousel.Item>
                <img src={Kathapadarofgallery22} className="HomeBannerImg" />
                    </Carousel.Item>
                    <Carousel.Item>
                    <img src={Kathapadarofgallery221} className="figure" />
                    </Carousel.Item>
                    <Carousel.Item>
                    <img src={Kathapadarofgallery222} className="figure" />
                    </Carousel.Item>
                    <Carousel.Item>
                    <img src={Kathapadarofgallery223} className="figure" />
                    </Carousel.Item>
                    <Carousel.Item>
                    <img src={Kathapadarofgallery224} className="figure" />
                    </Carousel.Item>
                    <Carousel.Item>
                    <img src={Kathapadarofgallery225} className="figure" />
                    </Carousel.Item>
                    <Carousel.Item>
                    <img src={Kathapadarofgallery226} className="figure" />
                    </Carousel.Item>
                    <Carousel.Item>
                    <img src={Kathapadarofgallery227} className="figure" />
                    </Carousel.Item>

                </Carousel>
                                        
                                    </div>

                                    <div class="col-md-12">
                                <p className="paraStyle">Visits were also organized at Chattrapati Sambhaji Nagar, the caves and Kailash Temple at Ellora. We were glad to have Tejaswini Aphale leading the walkthroughs along with Anurag Vaidya and Amogh Vaidya from TVAM's team.</p></div>

                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>



    {/*}        <div className="bgLightGrey customSpacing3">
                <div className="container">
                    <div className="row ">
                        <div className="container">
                            <div className="col-md-12">
                                <h2 className="text-center display2 marginBottom24px">PROGRAM GUIDE - OPENING DAY, 20 OCT 2023</h2>

                                <div class="table-responsive">
                                    <table>
                                        <tbody>
                                            <tr className="bgbrownPaithani">
                                                <td colSpan={2}>VENUE: MARHATI-MSSIDC WEAVING TRAINING CENTRE, PAITHAN </td>
                                            </tr>
                                            <tr>
                                                <td><b>10:45 - 10:55 am</b></td>
                                                <td>Welcome Address</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td><b>Mrs. Rasika Mhalgi Wakalkar</b></td>

                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td><b>Founder-Trustee, TVAM Foundation</b></td>
                                            </tr>

                                            <tr>
                                                <td><b>10:55 - 11 am</b></td>
                                                <td>Release of Monograph</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td><b>All special invitees of the morning</b></td>

                                            </tr>
                                            <tr>
                                                <td><b>11 - 11:10 am</b></td>
                                                <td>Blessings & An Overview of Paithan </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td><b>Dr. R.S. Morwanchikar, Eminent Historian & Scholar</b> </td>
                                            </tr>


                                            <tr>
                                                <td><b>11:10 - 11:25 am</b></td>
                                                <td>Personal Experiences with Weaving Cultures in Maharashtra: </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>A Journey Spanning 40 years  </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td><b>Rta Kapur Chishti, Sari Historian & Textile Scholar</b></td>
                                            </tr>

                                            <tr>
                                                <td><b>11:25- 11:35 am</b></td>
                                                <td>Paithan’s Archaeology and an Overview to Maharashtra’ </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>Museum Culture  </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td><b>Dr. Tejas Garge, Director, Directorate of Archaeology and Museums</b></td>
                                            </tr>
                                            <tr>
                                                <td><b>11:35 - 11:45 am</b></td>
                                                <td>The State Textile Policy 2023-2028 in the context of</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>Hand Weaves of Maharashtra</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td><b>Mr. Virendra Singh (I.A.S.), Hon. Secretary (Textiles), Government of Maharashtra</b></td>
                                            </tr>

                                            <tr>
                                                <td><b>11:45 - 11:55 am</b></td>
                                                <td>Marhati- MSSIDC Weaving Training Centre: An Overview</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td><b>Mr. Rajendra Nimbalkar, MD, MSSIDC</b></td>
                                            </tr>
                                            <tr>
                                                <td><b>11:55 am - 12:45 pm</b></td>
                                                <td>Insights into the Contemporary forms of the Paithani, Weaving & its Processes</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td><b>Interactions with Weavers at MSSIDC Center</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="table-responsive">
                                    <table>
                                        <tbody>
                                            <tr className="bgbrownPaithani">
                                                <td colSpan={2}>VENUE: SHRI BALASAHEB PATIL GOVERNMENT MUSEUM, PAITHAN</td>
                                            </tr>
                                            <tr>
                                                <td rowSpan={2}><b>2 - 2:30 pm </b></td>
                                                <td>Guided Walkthrough and Commentary on Historical Paithani</td>
                                            </tr>
                                            <tr>
                                                <td><b>Mayank Mansingh Kau
                                                    <br />Curator of Textiles, Kath Padar</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="table-responsive">
                                    <table style={{ marginBottom: "0px" }}>
                                        <tbody>

                                            <tr className="bgbrownPaithani" >
                                                <td colSpan={2}>VENUE: WADAS, ASI SITES, AND CULTURAL SITES OF PAITHAN</td>
                                            </tr>
                                            <tr>
                                                <td rowSpan={2}><b>3 - 6 pm </b></td>
                                                <td>Guided visits to Wadas, ASI sites, and cultural sites of Paithan</td>
                                            </tr>
                                            <tr>
                                                <td><b>Tejaswini Aphale, Conservation Architect, Archaeologist
                                                    <br />and Architectural Historian</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>

                        </div>


                    </div>
                </div>
            </div>  

            <div className="container">
                <div className="row customSpacing3">



                    <div className="container">
                        <div className="col-md-12">
                            <p className="text-center paraStyle"><b><a href="/#/registration-form" class="aUrl" style={{ textDecoration: "none" }}>Register for the Exhibition</a></b></p>
                        </div>

                    </div>


                </div>
            </div>

            <div className="">
                <div className="container">
                    <div className="row customSpacing3">

                        <div className="container">
                            <div className="col-md-12">
                                <h2 className="text-center display2 marginBottom24px">ABOUT THE DIRECTORATE OF ARCHAEOLOGY AND MUSEUMS,GOVERNMENT OF MAHARASHTRA </h2>
                                <div className="row">
                                    <p className="paraStyle">The Directorate of Archaeology and Museums functions under the Ministry of Cultural Affairs, Government of Maharashtra
                                        and is further divided into two branches- Archaeology and Museums. The Directorate operates through its sub-offices at
                                        Ratnagiri, Nashik, Pune, Aurangabad, Nanded and Nagpur for administrative purposes.</p>
                                    <p className="paraStyle">Discovery of historical monuments, places and artefacts, studying and documenting them, and declaring them as ‘State
                                        Protected Monuments’ so as to offer them legal protection are some of the main functions of the Directorate. It thus plays
                                        vital role in protection, conservation and preservation of our heritage. The Directorate also undertakes excavations at
                                        archaeological sites so as to bring to the light the glorious past of historical places. </p>
                                    <p className="paraStyle">The museums undertake very important job of preserving and showcasing the heritage of ancient India to the next
                                        generation. The Directorate has 13 museums under its jurisdiction that are home to artefacts unearthed during
                                        excavations and or those found during explorations, important historical objects, weapons and weaves, clothes and
                                        dresses, sculptures and art objects, artworks, inscriptions, structural members of the ancient- medieval period, paintings
                                        of revered artists etc.</p>





                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
*/}

        </Fragment>
    )
}
export default Paithani