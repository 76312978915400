import React, { Fragment, useEffect } from "react";
import Carousel from 'react-bootstrap/Carousel'
import BannerImage from '../../assets/images/discover.png'
import Discover1 from '../../assets/images/Discover/D1.jpg'
import Discover2 from '../../assets/images/Discover/D2.jpg'
import Discover3 from '../../assets/images/Discover/D3.jpg'
import Discover4 from '../../assets/images/Discover/D4.jpg'
import Discover5 from '../../assets/images/Discover/D5.jpg'
import Discover6 from '../../assets/images/Discover/D6.jpg'
import Discover7 from '../../assets/images/Discover/D7.jpg'
import Discover8 from '../../assets/images/Discover/D8.jpg'
import Discover9 from '../../assets/images/Discover/D9.jpg'
import Discover10 from '../../assets/images/Discover/D10.png'
import Discover11 from '../../assets/images/Discover/D11.png'

import { Helmet } from 'react-helmet';
const DiscoverWithTvam = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <Fragment>
             <Helmet>
         <title>Discover With TVAM | TVAM Foundation</title>
      <meta name="description" content="TVAM Foundation is committed to researching, celebrating, and informing the general public of the rich textile traditions of the Deccan and its narratives. "/>

         </Helmet>
            <img src={BannerImage} className='HomeBannerImg' />
           
           
                <div className="container">
                    <div className="row customSpacing3">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                            <div className="row">
                             
                                <div className="col-md-12">
                                    <p className="paraStyle">At TVAM Foundation, we believe in the causes of the textile histories of Deccan India and the related art, tangible and intangible culture, and heritage. One of our mandates is to spread awareness to the general public.</p>
                                    <p className="paraStyle">In the course of our discoveries on textile-related explorations, which is the main area of TVAM Foundation's work, we came across several heritage places in Pune that we felt needed to be prominent in public awareness.</p>
                                    <p className="paraStyle">Thus, we give back a bit to aapley Pune, and keep its history relevant to its future generations and guests who visit Pune.</p>
                                    <p className="paraStyle">Join us for curated trails with knowledgeable historians.
</p><div class="text-center CustomPadding2"><a itemprop="url" href="mailto:rutuja@tvamfoundation.com" class="aUrl">Customize an Itinerary with Us</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bgLightGrey">
            <div className="container">
                <div className="row customSpacing3">
                    <h2 className="text-center display2 marginBottom24px">OUR RECENT TRAILS</h2>
                   
                    <Carousel interval={3000} fade>
                <Carousel.Item>
              <div className="container">
                 <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-4">
                        <img src={Discover1} className="TrailsImg" />
                        </div>
                        <div className="col-md-4">
                        <img src={Discover2} className="TrailsImg" />
                        </div>
                        <div className="col-md-4">
                        <img src={Discover3} className="TrailsImg" />
                        </div>
                    </div>
                 </div>

              </div>
                    </Carousel.Item>
                    <Carousel.Item>
                    <div className="container">
                 <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-4 ">
                        <img src={Discover4} className="TrailsImg" />
                        </div>
                        <div className="col-md-4">
                        <img src={Discover5} className="TrailsImg" />
                        </div>
                        <div className="col-md-4">
                        <img src={Discover6} className="TrailsImg" />
                        </div>
                    </div>
                 </div>

              </div>
                    </Carousel.Item>
                    <Carousel.Item>
                    <div className="container">
                 <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-4">
                        <img src={Discover7} className="TrailsImg" />
                        </div>
                        <div className="col-md-4">
                        <img src={Discover8} className="TrailsImg" />
                        </div>
                        <div className="col-md-4">
                        <img src={Discover9} className="TrailsImg" />
                        </div>
                    </div>
                 </div>

              </div>
                    </Carousel.Item>

                    <Carousel.Item>
                    <div className="container">
                 <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-4">
                        <img src={Discover3} className="TrailsImg" />
                        </div>
                        <div className="col-md-4">
                        <img src={Discover10} className="TrailsImg" />
                        </div>
                        <div className="col-md-4">
                        <img src={Discover11} className="TrailsImg" />
                        </div>
                    </div>
                 </div>

              </div>
                    </Carousel.Item>
                   

                </Carousel>

                    

                </div>
            </div>
            </div>
            
        </Fragment>
    )
}
export default DiscoverWithTvam