import React, { Fragment, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import CatalogueBannerImage from '../../assets/images/catlogue-banner.jpg'
import publishingImage from '../../assets/images/paithani-image.jpg'
import BookofPaithani from '../../assets/images/book-for-website.png'

import CatalogueImage1 from '../../assets/images/catlogue-img1.jpg'
import Ordernow from '../../assets/images/order-now.jpg'
import Catlogueofimages from '../../assets/images/catlogue-images.jpg'


import { Helmet } from 'react-helmet';
const Catalogue = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [isOpen,setIsOpen] = React.useState(false);

    const showModal = () => {
        setIsOpen(true);
    };

    const hideModal = () => {
        setIsOpen(false);
    };


    return (
        <Fragment>
            <Helmet>
                <title>Kath Padar Catalogue | TVAM Foundation</title>
                <meta name="description" content="TVAM Foundation is committed to researching, celebrating, and informing the general public of the rich textile traditions of the Deccan and its narratives. " />

            </Helmet>
            <Modal show={isOpen} onHide={hideModal}>
                <Modal.Body>
                <div className="container">
                    <div className="row customSpacing3">
                        <div className="container">
                        <button className="closeButton" onClick={hideModal}>X</button>
                            <div className="col-md-12">
                            <p className="paraStyle text-center"><b>Pre-Order the Exhibition Catalogue</b></p>

                            <embed src="https://share.hsforms.com/1PlMHr_SMTdiKeLarS2Olqgq8qa1" width="100%" height="500" ></embed>
                            </div>

                        </div>


                    </div>
                </div>
    
                  


                    
                </Modal.Body>
            </Modal>
            
            <img src={CatalogueBannerImage} className='HomeBannerImg' />

            <div className="container">
                <div className="row customSpacing3">



                    <div className="container">
                        <div className="col-md-12">
                            <p className="text-center paraStyle"><b><a href="https://rzp.io/l/kath-padar-paithani-and-beyond" class="acUrl" style={{ textDecoration: "none" }} target="_blank">Order the Kāth Padar Catalogue for <br/>
INR 2200 inclusive of shipping in India*</a></b></p>

<p className="text-center paraStyle"><b>*For International orders please contact us at rutuja@tvamfoundation.com</b> </p>
                        </div>

                    </div>

                    <div className="container">
    <div className="row customSpacing33">
<div className="col-md-2"> <p></p></div>
<div className="col-md-8">
<img src={CatalogueImage1} className='figure' />

<div class="bgLightGrey">
    
    <p className="figcaption"><b>Release of the Kath Padar Catalogue</b> <br/> 
Seen from Left to Right: Rasika Wakalkar, Founder-Trustee, TVAM Foundation; Farogh Mukadam, Additional Collector and JMD, MSSIDC; Rta Kapur Chishti, Sari Historian and Textile Scholar; Dr. Tejas Garge, Director, Directorate of Archaeology and Museums, Govt. of Maharashtra; Mayank Mansingh Kaul, Textile Curator, Kāth Padar; Chetan Wakalkar, Trustee, TVAM Foundation
</p></div>  </div>
<div className="col-md-2"> <p></p></div>
</div> </div>

                </div>
            </div>

            <div className="bgLightGrey customSpacing3">
                <div className="container">
                    <div className="row marginBottom24px">
                        <div className="container">
                            <div className="col-md-12">
                                <div className="row">


                                    <div className="col-md-5">
                                        <img src={publishingImage} className="HomeBannerImg" />
                                    </div>

                                    <div className="col-md-7">
                                    <p className="paraStyle22">Kāth Padar — Paithani & Beyond has emerged through TVAM’s commissioning of research on the paithani. When we started our first explorations of this textile tradition in 2019, little did we realise its depth and extent!</p>

<p className="paraStyle22">This catalogue is an accompaniment to the exhibition Kāth Padar - Paithani & Beyond, showcased at the Shri Balasaheb Patil Government Museum, Paithan, displaying historical paithanis from the archives of the State Museums of Maharashtra.</p>

<p className="paraStyle22">The catalogue designed by <b>Reha Sodhi</b> captures images of the textiles showcased during the Kāth Padar exhibition along with a brief overview on the Paithani by <b>Mayank Mansingh Kaul</b> and commentaries on Paithan by <b>Dr. R.S. Morwanchikar</b>, one of the foremost historians on Paithan and its history, and <b>Dr. Tejas Garge</b>, Director, Directorate of Archaeology and Museums, Govt. of Maharashtra.</p>


<div className="container">
                        <div className="col-md-12">
                        <p className="text-center paraStyle"><b><a href="https://rzp.io/l/kath-padar-paithani-and-beyond" class="acUrl" style={{ textDecoration: "none" }} target="_blank">Order the Kāth Padar Catalogue for <br/>
INR 2200 inclusive of shipping in India*</a></b></p>

                        </div>

                    </div>

                                    </div>

            
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>

            <div className="customSpacing3">
                <div className="container">
                    <div className="row marginBottom24px">
                        <div className="container">
                            <div className="col-md-12">
                                <div className="row">

                                <div className="col-md-12">
                                        <img src={Ordernow} className="HomeBannerImg" />
                                    </div>
                             
                                    <div className="col-md-12  customSpacing3">
                                        <img src={Catlogueofimages} className="HomeBannerImg" />
                                    </div>


                                    

                                    <div class=""><p className="paraStyle text-center"><b>Order the Kath Padar catalogue to soak in the glorious textiles showcased at the exhibition.</b></p></div>

                                    <p className="text-center paraStyle"><b><a href="https://rzp.io/l/kath-padar-paithani-and-beyond" class="acUrl" style={{ textDecoration: "none" }} target="_blank">Order the Kāth Padar Catalogue for <br/>
INR 2200 inclusive of shipping in India*</a></b></p>
                     
                              {/*      <div class="text-center ">
                                        <p className="paraStyle text-center"><b><p  class="acUrl" onClick={showModal} >Order the Kāth Padar Catalogue for <br/>
INR 2200 inclusive of shipping in India*</p></b></p></div>  */}

                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>




 


        </Fragment>
    )
}
export default Catalogue