import './App.css';
import { Fragment } from 'react';
import Header from './component/Header';
import Footer from './component/Footer';
import Home from './pages/home/Home';
import About from './pages/about/About';
import Initiatives from './pages/initiatives/Initiatives';
import Contact from './pages/contact/Contact';
import Press from './pages/press/Press';
import Event from './pages/event/Event';
import Privacy from './pages/privacy-policy/Privacy';
import Terms from './pages/terms/Terms';
import Discover from './pages/Discover/discover-with-TVAM';
import Library from './pages/Library/liabrary-at-tvam';
import Publication from './pages/Publication/publication';
import Epiphanies from './pages/Epiphanies/epiphanies-in-Indian-Fashion';
import Paithani from './pages/Paithani/kath-padar-paithani-and-beyond';
import PaithaniForm from './pages/PaithaniForm/paithani-form';
import Catalogue from './pages/Catalogue/kath-padar-catalogue';
import {Route} from 'react-router-dom';
import { Helmet } from 'react-helmet';


function App() {
  return (
    
    <Fragment>
      <Helmet>
         <title>TVAM Foundation - Researching Textile Histories of Deccan India</title>
      <meta property="og:image" content="${require(`../../assets/images/home-page-banner-compressed-new.jpg`)}" /> 
      <meta name="description" content="TVAM Foundation is 12A & 80G approved, and enables research based curatorial & publishing projects on select textile histories of Deccan India."/>

         </Helmet>
      <Header></Header>
      <Route path="/" exact>
        <Home></Home>
      </Route>
      <Route path="/home">
        <Home></Home>
      </Route>
      <Route path="/about-us">
      <About></About>
      </Route>
      <Route path="/initiatives">
      <Initiatives></Initiatives>
      </Route>
      <Route path="/contact-us">
      <Contact></Contact>
      </Route>
      <Route path="/in-the-press">
      <Press></Press>
      </Route>
      <Route path="/event">
      <Event></Event>
      </Route>
      <Route path="/privacy-policy">
      <Privacy></Privacy>
      </Route>
      <Route path="/website-terms-of-use">
      <Terms></Terms>
      </Route>
      <Route path="/discover-with-TVAM">
      <Discover></Discover>
      </Route>

      <Route path="/library-at-tvam">
      <Library></Library>
      </Route>
      <Route path="/publication">
      <Publication></Publication>
      </Route>
      <Route  path="/epiphanies-in-Indian-Fashion">
        <Epiphanies></Epiphanies>
      </Route>
      <Route  path="/kath-padar-paithani-and-beyond">
        <Paithani></Paithani>
      </Route>
      <Route  path="/registration-form">
        <PaithaniForm></PaithaniForm>
      </Route>
      <Route  path="/kath-padar-catalogue">
        <Catalogue></Catalogue>
      </Route>
      
      <Footer></Footer>
    </Fragment>
  );
}

export default App;
