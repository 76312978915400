import React, { Fragment, useEffect } from "react";




import { Helmet } from 'react-helmet';
const PaithaniForm = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Fragment>
            <Helmet>
                <title>Kath Padar Paithani and Beyond | TVAM Foundation</title>
                <meta name="description" content="TVAM Foundation is committed to researching, celebrating, and informing the general public of the rich textile traditions of the Deccan and its narratives. " />

            </Helmet>
       
           
                <div className="container">
                    <div className="row customSpacing3">
                        <div className="container">
                            <div className="col-md-12">
                            <h2 className="text-center display2 marginBottom24px">Register for the Exhibition</h2>

                            <embed src="https://share.hsforms.com/1Aw5XIab2Twa67IxeVR8TBQq8qa1 " width="100%" height="1450" ></embed>
                            </div>

                        </div>


                    </div>
                </div>
            
            


        </Fragment>
    )
}
export default PaithaniForm