import React, { Fragment,  useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Helmet } from 'react-helmet';
import BannerImage from '../../assets/images/banner-inner.jpg'
import Image11 from '../../assets/images/11.jpg'
import Image12 from '../../assets/images/12.jpg'
import Image13 from '../../assets/images/13.jpg'
import Image14 from '../../assets/images/14.jpg'
import Image15 from '../../assets/images/15.jpg'
import Image161 from '../../assets/images/16.jpeg'
import Image17 from '../../assets/images/17.jpeg'
import Image18 from '../../assets/images/18.jpeg'
import Image19 from '../../assets/images/19.jpeg'
import Image20 from '../../assets/images/20.jpeg'
import Image21 from '../../assets/images/21.jpeg'
import Image22 from '../../assets/images/22.jpeg'

import Image16 from '../../assets/images/Live-Mint-Mayank-interview.jpg'
const Press = () => {
   
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

      const [isOpen,setIsOpen] = React.useState(false);

    const showModal = () => {
        setIsOpen(true);
    };

    const hideModal = () => {
        setIsOpen(false);
    };

    const [isOpen2,setIsOpen2] = React.useState(false);

    const showModal2 = () => {
        setIsOpen2(true);
    };

    const hideModal2 = () => {
        setIsOpen2(false);
    };

    const [isOpen3,setIsOpen3] = React.useState(false);

    const showModal3 = () => {
        setIsOpen3(true);
    };

    const hideModal3 = () => {
        setIsOpen3(false);
    };

    const [isOpen4,setIsOpen4] = React.useState(false);

    const showModal4 = () => {
        setIsOpen4(true);
    };

    const hideModal4 = () => {
        setIsOpen4(false);
    };

    const [isOpen5,setIsOpen5] = React.useState(false);

    const showModal5 = () => {
        setIsOpen5(true);
    };

    const hideModal5 = () => {
        setIsOpen5(false);
    };

    const [isOpen6,setIsOpen6] = React.useState(false);

    const showModal6 = () => {
        setIsOpen6(true);
    };

    const hideModal6 = () => {
        setIsOpen6(false);
    };

    const [isOpen7,setIsOpen7] = React.useState(false);

    const showModal7 = () => {
        setIsOpen7(true);
    };

    const hideModal7 = () => {
        setIsOpen7(false);
    };
    
    const [isOpen8,setIsOpen8] = React.useState(false);

    const showModal8 = () => {
        setIsOpen8(true);
    };

    const hideModal8 = () => {
        setIsOpen8(false);
    };

    const [isOpen9,setIsOpen9] = React.useState(false);

    const showModal9 = () => {
        setIsOpen9(true);
    };

    const hideModal9 = () => {
        setIsOpen9(false);
    };

    const [isOpen10,setIsOpen10] = React.useState(false);

    const showModal10 = () => {
        setIsOpen10(true);
    };

    const hideModal10 = () => {
        setIsOpen10(false);
    };

    const [isOpen11,setIsOpen11] = React.useState(false);

    const showModal11 = () => {
        setIsOpen11(true);
    };

    const hideModal11 = () => {
        setIsOpen11(false);
    };

    const [isOpen12,setIsOpen12] = React.useState(false);

    const showModal12 = () => {
        setIsOpen12(true);
    };

    const hideModal12 = () => {
        setIsOpen12(false);
    };

    const [isOpen13,setIsOpen13] = React.useState(false);

    const showModal13 = () => {
        setIsOpen13(true);
    };

    const hideModal13 = () => {
        setIsOpen13(false);
    };



    return (
        <Fragment>
     <Helmet>
         <title> Press Centre | TVAM Foundation</title>
      <meta name="description" content="Read the latest coverage on the activities and initiatives of TVAM Foundation in the leading newspapers of India.  "/>

         </Helmet>
          <Modal show={isOpen} onHide={hideModal}>
                <Modal.Body>
                <div className="container">
                    <div className="col-md-12">
                    <button className="closeButton" onClick={hideModal}>X</button>
                        <img src={Image11} className='img_res' />
                    
                    </div>
                 </div>
    
                  


                    
                </Modal.Body>
            </Modal>

            <Modal show={isOpen2} onHide={hideModal2}>
                <Modal.Body>
                <div className="container">
                    <div className="col-md-12">
                    <button className="closeButton" onClick={hideModal2}>X</button>
                        <img src={Image15} className='img_res' />
                    
                    </div>
                 </div>
    
                  


                    
                </Modal.Body>
            </Modal>

            <Modal show={isOpen3} onHide={hideModal3}>
                <Modal.Body>
                <div className="container">
                    <div className="col-md-12">
                    <button className="closeButton" onClick={hideModal3}>X</button>
                        <img src={Image12} className='img_res' />
                    
                    </div>
                 </div>
    
                  


                    
                </Modal.Body>
            </Modal>

            <Modal show={isOpen4} onHide={hideModal4}>
                <Modal.Body>
                <div className="container">
                    <div className="col-md-12">
                    <button className="closeButton" onClick={hideModal4}>X</button>
                        <img src={Image13} className='img_res' />
                    
                    </div>
                 </div>
    
                  


                    
                </Modal.Body>
            </Modal>

            <Modal show={isOpen5} onHide={hideModal5}>
                <Modal.Body>
                <div className="container">
                    <div className="col-md-12">
                    <button className="closeButton" onClick={hideModal5}>X</button>
                        <img src={Image16} className='img_res' />
                    
                    </div>
                 </div>
    
                  


                    
                </Modal.Body>
            </Modal>

            <Modal show={isOpen6} onHide={hideModal6}>
                <Modal.Body>
                <div className="container">
                    <div className="col-md-12">
                    <button className="closeButton" onClick={hideModal6}>X</button>
                        <img src={Image14} className='img_res' />
                    
                    </div>
                 </div>
    
                  


                    
                </Modal.Body>
            </Modal>


            <Modal show={isOpen7} onHide={hideModal7}>
                <Modal.Body>
                <div className="container">
                    <div className="col-md-12">
                    <button className="closeButton" onClick={hideModal7}>X</button>
                        <img src={Image161} className='img_res' />
                    
                    </div>
                 </div>
    
                  


                    
                </Modal.Body>
            </Modal>


            <Modal show={isOpen8} onHide={hideModal8}>
                <Modal.Body>
                <div className="container">
                    <div className="col-md-12">
                    <button className="closeButton" onClick={hideModal8}>X</button>
                        <img src={Image17} className='img_res' />
                    
                    </div>
                 </div>
    
                  


                    
                </Modal.Body>
            </Modal>

            <Modal show={isOpen9} onHide={hideModal9}>
                <Modal.Body>
                <div className="container">
                    <div className="col-md-12">
                    <button className="closeButton" onClick={hideModal9}>X</button>
                        <img src={Image18} className='img_res' />
                    
                    </div>
                 </div>
    
                  


                    
                </Modal.Body>
            </Modal>

            <Modal show={isOpen10} onHide={hideModal10}>
                <Modal.Body>
                <div className="container">
                    <div className="col-md-12">
                    <button className="closeButton" onClick={hideModal10}>X</button>
                        <img src={Image19} className='img_res' />
                    
                    </div>
                 </div>
    
                  


                    
                </Modal.Body>
            </Modal>

            <Modal show={isOpen11} onHide={hideModal11}>
                <Modal.Body>
                <div className="container">
                    <div className="col-md-12">
                    <button className="closeButton" onClick={hideModal11}>X</button>
                        <img src={Image20} className='img_res' />
                    
                    </div>
                 </div>
    
                  


                    
                </Modal.Body>
            </Modal>

            <Modal show={isOpen12} onHide={hideModal12}>
                <Modal.Body>
                <div className="container">
                    <div className="col-md-12">
                    <button className="closeButton" onClick={hideModal12}>X</button>
                        <img src={Image21} className='img_res' />
                    
                    </div>
                 </div>
    
                  


                    
                </Modal.Body>
            </Modal>

            <Modal show={isOpen13} onHide={hideModal13}>
                <Modal.Body>
                <div className="container">
                    <div className="col-md-12">
                    <button className="closeButton" onClick={hideModal13}>X</button>
                        <img src={Image22} className='img_res' />
                    
                    </div>
                 </div>
    
                  


                    
                </Modal.Body>
            </Modal>

           

            <img src={BannerImage} className='HomeBannerImg' />
            <div className="container">
                <div className="row customSpacing3">
                    <h2 className="text-center display2 marginBottom24px">IN THE PRESS</h2>
                    <h2 className="text-center innerSubHeading marginBottom24px">The Story of TVAM Foundation</h2>
                </div>
            </div>
            <div className="container">
                <div className="" style={{marginBottom:'5em'}}>
                   <div className="row">
                       <div className="col-md-3 frame1">
                       <div className="row">

                       <div className="col-md-12 frame1" onClick={showModal5} style={{cursor:'pointer'}}>
                       <img src={Image16} className='HomeBannerImg' />
                       </div>
                       <div className="col-md-12 frame1" onClick={showModal} style={{cursor:'pointer'}}>
                       <img src={Image11} className='HomeBannerImg' />
                       </div>
                       
                       <div className="col-md-12 frame1" onClick={showModal2} style={{cursor:'pointer'}}>
                       <img src={Image15} className='HomeBannerImg' />
                       </div>
                       
                       </div>
                       </div>
                       <div className="col-md-3 frame1" onClick={showModal3} style={{cursor:'pointer'}}>
                       <img src={Image12} className='HomeBannerImg vericalMiddle' />
                       </div>
                       <div className="col-md-3 frame1" onClick={showModal4} style={{cursor:'pointer'}}>
                       <img src={Image13} className='HomeBannerImg' />
                       </div>
                       <div className="col-md-3 frame1" onClick={showModal6} style={{cursor:'pointer'}}>
                       <img src={Image14} className='HomeBannerImg' />
                       </div>
                       <div className="col-md-3 frame1" onClick={showModal7} style={{cursor:'pointer'}}>
                       <img src={Image15} className='HomeBannerImg' />
                       </div>
                       <div className="col-md-3 frame1" onClick={showModal8} style={{cursor:'pointer'}}>
                       <img src={Image161} className='HomeBannerImg' />
                       </div>
                       <div className="col-md-3 frame1" onClick={showModal9} style={{cursor:'pointer'}}>
                       <img src={Image17} className='HomeBannerImg' />
                       </div>
                       <div className="col-md-3 frame1" onClick={showModal10} style={{cursor:'pointer'}}>
                       <img src={Image18} className='HomeBannerImg' />
                       </div>
                       <div className="col-md-3 frame1" onClick={showModal11} style={{cursor:'pointer'}}>
                       <img src={Image19} className='HomeBannerImg' />
                       </div>
                       <div className="col-md-3 frame1" onClick={showModal12} style={{cursor:'pointer'}}>
                       <img src={Image20} className='HomeBannerImg' />
                       </div>
                       <div className="col-md-3 frame1" onClick={showModal13} style={{cursor:'pointer'}}>
                       <img src={Image21} className='HomeBannerImg' />
                       </div>
                       <div className="col-md-3 frame1" onClick={showModal13} style={{cursor:'pointer'}}>
                       <img src={Image22} className='HomeBannerImg' />
                       </div>
                       
                   </div>
                </div>
            </div>
        </Fragment>
    )
}
export default Press