import React, { Fragment, useEffect } from "react";
import { Helmet } from 'react-helmet';
import BannerImage from '../../assets/images/banner-inner.jpg'
const Contact =()=>{
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return(
        <Fragment>
             <Helmet>
         <title> Connect with US | TVAM Foundation</title>
      <meta name="description" content="We are delighted by your interest in joining hands with TVAM Foundation and co-creating value. Get in touch to help us make a difference "/>

         </Helmet>
            <img src={BannerImage} className='HomeBannerImg' />
            <div className="container">
                <div className="row customSpacing3">
                    <h2 className="text-center display2">GET IN TOUCH</h2>
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-md-12">
                                <p className="paraStyle display-5">Do you want to join us in co-creating value? Do you want to learn more about TVAM Foundation and the work we do?</p>
                                <p className="paraStyle display-5">If you are a student, researcher, textile enthusiast, scholar, enabler, or donor, please leave us message.</p>
                                <p className="paraStyle display-5">We would love to engage with you!</p>
                                {/* <p className="paraStyle display-5"> <b>Address</b></p>
                                <p> FLAT NO-10 BUILDING G-S,NO-B 138, <br /> /1A/1 138/1A/2 AND 136/2/2 CONIFER,  <br />
                            APPTS OPP-D P ROAD AUNDH <br />
                            PUNE MAHARASHTRA-INDIA-411007</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <div className="row">
                            {/* <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-4 inputGroup">
                                        <p className="paraStyle">Name</p>
                                        <input type="text" className="inputType"/>
                                    </div>
                                    <div className="col-md-4 inputGroup">
                                    <p className="paraStyle">Email</p>
                                        <input type="text" className="inputType"/>
                                    </div>
                                    <div className="col-md-4 inputGroup">
                                    <p className="paraStyle">Phone</p>
                                        <input type="text" className="inputType"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 inputGroup">
                                        <p className="paraStyle">Message</p>
                                        <textarea className="inputTypeTextArea"></textarea>
                                    </div>
                                </div>
                                <div className="text-center">
                                <button type="submit" className="formSubmit">SEND</button>
                                </div>
                            </div> */}
                            <embed src="https://share.hsforms.com/1sV42MpkNSUukd2l9B-gFlgnm164" width="100%" height="600" ></embed>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default Contact