import React, { Fragment, useEffect } from "react";
import Carousel from 'react-bootstrap/Carousel'
import BannerImage from '../../assets/images/publishing.png'
import publishingImage from '../../assets/images/publishingImg.jpg'



import { Helmet } from 'react-helmet';
const Publication = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <Fragment>
             <Helmet>
         <title>Publishing by TVAM| TVAM Foundation</title>
      <meta name="description" content="TVAM Foundation is committed to researching, celebrating, and informing the general public of the rich textile traditions of the Deccan and its narratives. "/>

         </Helmet>
            <img src={BannerImage} className='HomeBannerImg' />
           
           
                <div className="container">
                    <div className="row customSpacing3">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                            <div className="row">
                             
                                <div className="col-md-12">
                                    <p className="paraStyle">TVAM Foundation is an Institutional Publisher for research papers, monographs, books and the likes, recognised by the ISBN agency of India, Ministry of Education.
                                    All proceeds from the sale of the books published by TVAM Foundation are used to fund our projects 
                                    and initiatives.</p>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
     <div className="bgLightGrey">
            <div className="container">
                <div className="row customSpacing3">
                    <h2 className="text-center display2 marginBottom24px">OUR PUBLICATIONS</h2>
                   
                  
              <div className="container">
                 <div className="col-md-12">
                    <div className="row">
                    <div className="col-md-7">
                        <p className="paraStyle"> A first-hand account and a front row seat, to all that goes on at the back end of fashion and retail, as experienced by Rasika Wakalkar 
                         in her 15-years old entrepreneurial journey as a fashion insider. </p>
                         <p className="paraStyle">Entrepreneurs, fashionistas, designers, consumers, and students of fashion would find a holistic understanding and overview to Indian fashion through the eyes of the author, as she brings her learnings to readers to help 
                         their growth and interest in Indian fashion.</p>
                         <p className="paraStyle">All proceeds received from the book sales are entirely donated towards TVAM Foundation's
                          projects and administrative functions.</p>

                          <div class="text-center CustomPadding2"><a itemprop="url" href="https://rzp.io/l/vdlO0U0Vee
" target="_blank" class="aUrl">Order at a Special Pre-Order Price</a></div>
                        </div>

                        <div className="col-md-5">
                        <img src={publishingImage} className="HomeBannerImg" />
                        </div>
                       
                       
                       
                    </div>
                 </div>

              </div>
                    

                </div>
            </div>
            </div>
            
        </Fragment>
    )
}
export default Publication