import React, { Fragment, useEffect } from "react";
import Carousel from 'react-bootstrap/Carousel'
import Eventbanner from '../../assets/images/epiphanies-Banner.png'
import BannerImage from '../../assets/images/home-page-banner-compressed-new.jpg'
import BannerImagePaithani from '../../assets/images/kathapadar-banner.jpg'
import ResearchImg from '../../assets/images/h-img-1.jpg'
import DesignImg from '../../assets/images/h-img-2.jpg'
import SupportImg from '../../assets/images/h-img-3.jpg'
import { Helmet } from 'react-helmet';
const Home = () => {
    // useEffect(() => {
    //     window.scrollTo(0, 0)
    //   }, [])


   
    return (
        <Fragment>
            <Helmet>
         <title>TVAM Foundation - M/S The Vishwas & Anuradha Memorial Foundation - Researching Textile Histories of Deccan India</title>
      <meta name="description" content="TVAM Foundation is 12A & 80G approved, and enables research based curatorial & publishing projects on select textile histories of Deccan India."/>

         </Helmet>
            <Carousel interval={3000} fade>
                <Carousel.Item>
                <img src={BannerImage} className="HomeBannerImg" />
                    </Carousel.Item>
                    <Carousel.Item>
                    <img src={BannerImagePaithani} className="HomeBannerImg" />
                    </Carousel.Item>
                    <Carousel.Item>
                    <img src={Eventbanner} className="HomeBannerImg" />
                    </Carousel.Item>
                    
                   

                </Carousel>
            <div className="container">
                <div className="row customSpacing1">
                    <div className="col-md-2"></div>
                    <div className="col-md-8 text-center">
                        <div className="separator"></div>
                        <h4><em>TVAM Foundation's vision is to strive for a positive transformation through researched knowledge dissemination of the weaving cultures and heritage of the Deccan, and to meaningfully impact the livelihoods of textile communities and ecosystems therein.</em></h4>
                       
                    </div>
                </div>
            </div>
            <div className="bgLightGrey">
                <div className="container">
                    <div className="row customSpacing1">
                        <h2 className="text-center display2">THE IMPACT WE HOPE FOR</h2>
                        <div className="col-md-4">
                            <img src={ResearchImg} className="imgRes" />
                            <h5 className="cardTitle">RESEARCH, DOCUMENTATION &amp; ARCHIVING</h5>
                            <h6 className="cardDescription">Commissioning books, publications, and digital and social content that aid a heritage perspective and help us build the most reliable textile fashion archives in history for the Deccan Region.</h6>
                        </div>
                        <div className="col-md-4">
                            <img src={DesignImg} className="imgRes" />
                            <h5 className="cardTitle">DESIGN INTERVENTIONS AND SKILLING</h5>
                            <h6 className="cardDescription">Connecting various networks, like designers, craft people, weavers, curators, scholars, enablers, influencers, end customers to positively upgrade skillsets, and thus encouraging co-creation.</h6>
                        </div>
                        <div className="col-md-4">
                            <img src={SupportImg} className="imgRes" />
                            <h5 className="cardTitle">CURATORIAL EXHIBITIONS</h5>
                            <h6 className="cardDescription">Curating exhibitions which bring forth awareness about the research conducted. We aim to promote awareness and knowledge appreciation of intangible and tangible textile cultures.</h6>
                        </div>
                        <div className="text-center CustomPadding2">
                            <a itemProp="url" href="mailto:reachus@tvamfoundation.com" target="_blank" className="aUrl">CONNECT TO LEARN &amp; CO-CREATE</a>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    )
}
export default Home