import React, { Fragment, useEffect } from "react";
import BannerImage from '../../assets/images/epiphanies-Banner.png'
import publishingImage from '../../assets/images/publishingImg.jpg'
import RasikaImage from '../../assets/images/rasika-pro.jpg'

import Paperback from '../../assets/images/paperback.png'
import Hardcover from '../../assets/images/hardcover.png'
import Ebook from '../../assets/images/e-book.png'
import footerICON from '../../assets/images/footer-ICON.jpg'




import { Helmet } from 'react-helmet';
const Epiphanies = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <Fragment>
             <Helmet>
         <title>Epiphanies in Indian Fashion | TVAM Foundation</title>
      <meta name="description" content="TVAM Foundation is committed to researching, celebrating, and informing the general public of the rich textile traditions of the Deccan and its narratives. "/>

         </Helmet>
            <img src={BannerImage} className='HomeBannerImg' />
           
           
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                           
                                <div className="col-md-4">
                                <div class="text-center  CustomPadding2Btn"><a itemprop="url" href="https://rzp.io/l/zjhY6QlQnQ
" target="_blank" class="aUrl"> Order Paperback for ₹ 900</a></div>
                                   
                                </div>

                                <div className="col-md-4">
                                <div class=" text-center  CustomPadding2Btn"><a itemprop="url" href="https://rzp.io/l/DD5dId18f
" target="_blank" class="aUrl"> Order Hardcover for ₹ 1250</a></div>
                                   
                                </div>

                                <div className="col-md-4">
                                <div class="text-center  CustomPadding2Btn"><a itemprop="url" href="https://rzp.io/l/vdlO0U0Vee
" target="_blank" class="aUrl"> Order E-Book for ₹ 350 </a></div>
                                </div>
                              
                                {/* <div className="col-md-5 desktopHide">
                                <div class="text-left" style={{marginBottom:"2em"}}><a itemprop="url" href="https://rzp.io/l/vdlO0U0Vee
" target="_blank" class="aUrl">Pre-Order E-Book for ₹ 199</a></div>
                                </div> */}
                                <h2 className="text-left display2" style={{marginBottom:"0px"}}>ABOUT THE BOOK</h2> 

                            </div>
                        </div>
                    </div>
                </div>
     <div className="bgLightGrey">
            <div className="container">
                <div className="row customSpacing3" style={{paddingTop:"2em"}}>
              <div className="container">
                 <div className="col-md-12" >
                
                    <div className="row">
                    {/* <div className="col-md-5">
                        <img src={publishingImage} className="HomeBannerImg" />
                        </div> */}

                    <div className="col-md-12">
            
                        <p className="paraStyle">When the author started Rudraksh, a well known Indian
                        multi-designer store in Pune, there was no one to guide her or say what mistakes to avoid, what leverage to use, how to build a fashion 
                        brand and its business, or how to use and deploy capital and resources. Rasika made quite a few (expensive) mistakes but 
                        fortunately has had more successes to build upon, with a tremendous amount of perseverance, passion, and commitment.
                        In this book, she brings her learnings to readers to help their growth and interest in Indian fashion.

                        </p>
                         <p className="paraStyle">Does the fashion supply chain understand interconnected nuances? Do clients appreciate creativity, or do they put a price which is their perception
                          of a good price for a garment? What does a student learn at fashion school? How much does one have to unlearn? Does the designer ever lose the plot from the runway to the store? 
                         Will the stylist, the press, the client choose mindful style over fast fashion? These are some questions she explores.</p>

                         <p className="paraStyle">She looks at fashion from the perspective of a client first, who became the retail bridge, then a patron and finally an observer.
                          Her chronicles and observations will leave you with questions of your own and if those questions 
                         already ever existed in your mind, then you may be able to find some answers, or your own epiphany event! </p>

                         <p className="paraStyle"> Here is her first-hand account and a front row seat, to all that goes on at the back end of fashion and retail as experienced in her 15-years-old entrepreneurial journey as a fashion insider.
                         Entrepreneurs, fashionistas, designers, consumers, and students of fashion would find a holistic understanding and overview to Indian fashion through the eyes of the author.</p>

                         <p className="paraStyle">The cover page is drawn from the artwork of artist Sujata Bajaj, the book is edited by Dr. Monisha Ahmed and the foreword is written by senior designer, Rakesh Thakore.</p>
                        </div>
                       
                        <div className="col-md-4">

                        <img src={Paperback} className="HomeBannerImg" />


                                <div class="text-center CustomPadding2"><a itemprop="url" href="https://rzp.io/l/zjhY6QlQnQ
" target="_blank" class="aUrl"> Order Paperback for ₹ 900</a></div>
                                   
                                </div>

                                <div className="col-md-4">

                                <img src={Hardcover} className="HomeBannerImg" />


                                <div class="text-center CustomPadding2"><a itemprop="url" href="https://rzp.io/l/DD5dId18f
" target="_blank" class="aUrl"> Order Hardcover for ₹ 1250</a></div>
                                   
                                </div>

                                <div className="col-md-4">
                                <img src={Ebook} className="HomeBannerImg" />


                                <div class="text-center CustomPadding2"><a itemprop="url" href="https://rzp.io/l/vdlO0U0Vee
" target="_blank" class="aUrl"> Order E-Book for ₹ 350</a></div>
                                </div>
                               
                                {/* <div className="col-md-6 desktopHide">
                                <div class="text-left" style={{marginBottom:"2em"}}><a itemprop="url" href="https://rzp.io/l/vdlO0U0Vee
" target="_blank" class="aUrl">Pre-Order E-Book for ₹ 199</a></div>
                                </div> */}
                       
                       
                       
                    </div>
                 </div>

              </div>
                    

                </div>
            </div>
            </div>
            <div className="container">
                <div className="row customSpacing3">                  
              <div className="container">
                 <div className="col-md-12">
                 <h2 className="text-center display2 marginBottom24px">ABOUT THE AUTHOR</h2>
                    <div className="row">
                        <div className="col-md-3">
                        <img src={RasikaImage} className="HomeBannerImg" />
                        </div>
                        <div className="col-md-9">
                        <p className="paraStyle">Rasika Wakalkar, as a founder of Pune's multi-designer store, Rudraksh has been acknowledged as a 
                        thought leader and taste maker in the Indian fashion space. She has been widely covered in press, with multiple recognitions and awards
                         to her credit. Over the last 15 years, Rudraksh as a multi designer store in Pune has retailed more than 300 known and lesser-known Indian fashion labels and is known to pick designers before they go on to become well established names.</p>


                         <p className="paraStyle">Rasika's work experience spans 23 years+ across leadership roles in strategy, marketing & brand positioning in the education and fashion space. </p>
                          <p className="paraStyle" style={{marginBottom:"0px"}}>Her journey in fashion exposed the many gaps in it and she wished to give
                            back to the grassroots and her ecosystems. </p>
                            
                        </div>
                        <div className="col-md-12">
                        <p  className="paraStyle">She found her purpose and started The Vishwas & Anuradha Memorial [TVAM] Foundation in the memory of her late parents in March 2019, The not-for-profit 
                         Foundation is committed to researching,
                          celebrating, and informing the general public of the rich textile traditions of Deccan India, and Maharashtra in particular. </p>
                          <p  className="paraStyle">  Rasika has been very actively involved with Industry and Social Organisations like CII-Yi, Entrepreneurs Organisation (EO), and more, over the last 2 decades in various leadership roles. She has been an official CII-Yi delegate to the G20 YEA Summits in France and Mexico.</p>

                        <p className="paraStyle">She is the Cultural Ambassador for the Asia Society, an exclusive community comprising people who are passionate and curious about the arts of the region and interested in getting more involved with the cultural ecosystem of South Asia.
                         She is also the Founding Chapter Champion for Pune, for Ladies Who Lead, a Club of
                    Women entrepreneurs, leaders and business owners across India.</p>
                    <p className="paraStyle">Between 2020-22, she pursued a few of her deep, diverse interests and successfully completed multiple short courses,
                     one on Styling and Image Making from Business of Fashion, London: An Introduction to Indian Heritage which was an intensive course from the prestigious 
                     Bhandarkar Oriental Research Institute (BORI). Understanding Public Policy in India from the Indian School of Business (ISB) Hyderabad, a certificate course on Pranayama from Param Yoga Institute, Money 
                    Gym and Stock Investments from Women on Wealth, Sustainable Agriculture Foundation Course from Suhasta.</p>
                    <p className="paraStyle"><b>Trust the process is her philosophy and approach to life.</b></p>
                        </div>
                       
                    </div>
                 </div>

              </div>
                    

                </div>
            </div>
            <div className="bgLightGrey">
            <div className="container">
                <div className="row customSpacing3">
              <div className="container">
                 <div className="col-md-12">
                    <div className="row">
                    <h2 className="text-left display2">PRODUCT DESCRIPTION</h2> 
                    <p className="paraStyle"><b>ASIN:</b>BOBZWWTRNO</p>
                    <p className="paraStyle"><b>ISBN:</b>978-81-962924-0-9</p>
                    <p className="paraStyle"><b>Author: </b>Rasika Wakalkar</p>
                    <p className="paraStyle"><b>Publisher: </b>TVAM Foundation</p>
                    <p className="paraStyle"><b>Language:</b>English</p>
                    <p className="paraStyle"><b>Screen Reader: </b>Supported</p>
                    <p className="paraStyle"><b>Enhanced typesetting: </b>Enabled</p>
                    <p className="paraStyle"><b>Print length:</b> 267 Pages</p>
                    </div>
                     </div>

                   </div>
                </div>
            </div>
            </div>
            <div className="container">
                <div className="row customSpacing3">                  
              <div className="container">
                 <div className="col-md-12">
                    <div className="row">
                    <h2 className="display2 marginBottom24px">ALSO  AVAILABLE  ON</h2>
                   

                    <img src={footerICON} className="HomeBannerImg" />
                    </div>


                 </div>

              </div>
                    

                </div>
            </div>
            
        </Fragment>
    )
}
export default Epiphanies