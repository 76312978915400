import React, { Fragment, useState, useEffect, useRef } from "react";
import LogoWhite from '../assets/images/tvam-foundation-logo-w.png'
const Footer = () => {
    const [height, setHeight] = useState(0)
  const ref = useRef(null)
    useEffect(() => {
        setHeight(ref.current.clientHeight)
      })
    return (
        <Fragment>
            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <img src={LogoWhite} className="logoFooter" />
                            <p className="logotext">The Vishwas & Anuradha Memorial Foundation</p>
                            <br />
                             
                            TVAM Foundation is 12A &amp; 80G approved <br />
                            Regn. Number: E-8454/P <br />
                            FLAT NO-10 BUILDING G-S,NO-B 138, <br /> /1A/1 138/1A/2 AND 136/2/2 CONIFER,  <br />
                            APPTS OPP-D P ROAD AUNDH <br />
                            PUNE MAHARASHTRA-INDIA-411007<br />
                            Mobile No: +91 93705 97762  <br />
                       {/*     FLAT NO-10 BUILDING G-S,NO-B 138, <br /> /1A/1 138/1A/2 AND 136/2/2 CONIFER,  <br />
                            APPTS OPP-D P ROAD AUNDH <br />
                            PUNE MAHARASHTRA-INDIA-411007  */}
                            <br/>
                            <br/>
                            <a href="#/privacy-policy" rel="noreferrer" className="footerLinka">Privacy Policy</a>
                            <a href="#/website-terms-of-use" rel="noreferrer" className="footerLinka">Terms of Use.</a>
                            <p>Donations made to the Foundation are non-refundable</p>
                        </div>
                        <div className="col-md-4">
                        <iframe ref={ref} src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Ftvamfoundation&tabs=timeline&width=340&height=350&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="340" height="350" style={{border:'none', overflow:'hidden'}} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                        </div>
                        <div className="col-md-4" style={{height:height, overflow:'hidden'}}>
                        
<div className="elfsight-app-f8ea2421-889e-4e1f-a444-848393111183"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footerBottom">© Copyright 2020 TVAM Foundation - All Rights Reserved</div>
        </Fragment>
    )
}
export default Footer