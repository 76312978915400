import React, { Fragment, useEffect } from "react";
import BannerImage from '../../assets/images/banner-inner.jpg'
const Privacy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <Fragment>
            <img src={BannerImage} className='HomeBannerImg' />
            <div className="container">
                <div className="row customSpacing3">
                    <h2 className="text-center display2">PRIVACY POLICY</h2>
                    <div className="col-md-12">
                        <div className="wpb_wrapper">
                            <p>Effective date: 02/04/2021</p>
                            <p>Please read the following carefully to understand our views and practices regarding your personal data.</p>
                            <h3>A. GENERAL</h3>
                            <p><strong>1.</strong> This Website with the URL of www.tvamfoundation.com (“Website/Site”) is owned and operated by TVAM Foundation (“We/Our/Us”). We are committed to protecting and respecting your privacy according to this Privacy Policy We comply with legal frameworks and the applicable laws, in spirit, relating to the collection, storing, transferring, and processing of personal data, wherein such applicable laws.</p>
                            <p><strong>2.</strong> All partner firms and / or any third-party working with or for Us, and who have access to personal information, will be expected to read and comply with this privacy policy. .</p>
                            <h3>B. INFORMATION WE COLLECT</h3>
                            <p><strong>a.</strong> Voluntary Disclosure by youWe collect your registration information such as Name, email address, contact number from you when you use or signup on our Website.</p>
                            <p><strong>b.</strong> We may collect your billing details, If you make payments for using the paid services and / or products, we would require you to provide your billing details such as name, email address, financial information corresponding to your selected method of payment, location, etc.</p>
                            <p><strong>c.</strong> We may collect your details with regards to your preferences for the account settings such as communication preferences (example opting in or out of receiving marketing emails from TVAM, newsletters), etc.</p>
                            <p><strong>d.</strong> We may collect and store data provided by you if you participate in any survey held by us.</p>
                            <p><strong>e.</strong> We may collect your other personal information or data, if you submit it to us for any other purpose or context. For example, if you provide us with testimonials or participate in survey.</p>
                            <p>If you have supplied us with personal information relating to another individual or a third party, you must ensure that you have done so in compliance with the applicable law and provide the information to them which is required to be provided to individuals under such law(s). This includes making them aware of their rights and of how we use their data and obtaining their consent where it is required in accordance with applicable law(s).</p>
                            <p><strong>1. Collection of information indirectly and / or passively</strong><br />
                                <strong>a.&nbsp;</strong>When you visit our Site, some information is automatically collected. This may include information such as the Operating Systems (OS) running on your device, Internet Protocol (IP) address, access times, browser type, and language, and the website you visited before our Site. We also collect information about how you use Our products or services.</p>
                            <p><strong>b.&nbsp;</strong>We automatically collect purchase or content use history, which we sometimes aggregate with similar information from other users/ customers to create unique website features.</p>
                            <p><strong>c.</strong> We may collect your personal information or data from third parties, if you give permission to those third parties to share your information with us, such as in a scenario you opt for registering and signing into our website with your social media (such as Facebook, etc.) account details; wherein the authentication of your logon details is handled by that social media and we only collect information that you expressly agree to share with us through that social media website.</p>
                            <p><strong>d.</strong> The full Uniform Resource Locators (URL) clickstream to, through and from our website (including date and time); cookie number; products and/or content you viewed or searched for; page response times; download errors; length of visits to certain pages; page interaction information (such as scrolling, clicks, and mouse-overs).</p>
                            <p><strong>e.</strong> We automatically collect information using “Cookies” to optimize our web presence.</p>
                            <ul>
                                <li>Cookies are small data / text files stored on your devices’ hard drive / main memory. Among other things, cookies help us improve our Site, our marketing activities, and your experience. We use cookies to see which areas and features are popular and to count visits to our Site.</li>
                                <li>These cookies are deleted after you close the browser. Other cookies remain on your computer (long-term cookies) and permit its recognition on your next visit. This allows us to improve your access to our site. This helps us to learn more about your interests, and provide you with essential features and services and for additional purposes, including:<br />
                                    <strong>I.</strong> Keeping track of items stored in your shopping basket.<br />
                                    <strong>II.</strong> Conducting research and diagnostics to improve the content, products, and services.<br />
                                    <strong>III.</strong> Preventing fraudulent activity.<br />
                                    <strong>IV.</strong> Improving security.</li>
                                <li>Most Web browsers are set to accept cookies by default. If you prefer, you can choose to set your browser to remove cookies and to reject cookies. If you set your browser to reject cookies, some features will be unavailable. For more information on how to reject cookies, see your browser’s instructions on changing your cookie settings. You can prevent the storage of cookies by choosing a “disable cookies” option in your browser settings. But this can limit the functionality of our services.</li>
                                <li>Our cookies allow you to take advantage of some of our essential features. For instance, if you block or otherwise reject our cookies, you will not be able to add items to your shopping basket, proceed to checkout, or use any products or services that require you to sign in.</li>
                                <li>Approved third parties also may set cookies when you interact with Our services. Third parties include search engines, providers of measurement and analytics services, social media networks, and advertising companies.</li>
                                <li>Third parties use cookies in the process of delivering content, including ads relevant to your interests, to measure the effectiveness of their ads, and to perform services on behalf of Us</li>
                            </ul>
                            <p><strong>1.</strong><br />
                                <strong>2</strong>.</p>
                            <h3>C. YOUR INFORMATION CHOICES AND CHANGES</h3>
                            <p>We may retain your information, services. You can also make choices about the collection and processing of your data by Us as mentioned below:</p>
                            <p><strong>1)</strong> To certain extent you can manage your privacy, for instance, you may choose to or not to share in your details including but not limited to your name, email address, etc., you can also choose to browse the web privately in incognito mode.</p>
                            <p><strong>2)</strong> You can access your personal data and opt-out of certain services provided by the Us. In some cases, your ability to control of, access to, retaining of your data subject to applicable laws.</p>
                            <p><strong>3)</strong> You may opt-out of receiving promotional emails from Us by following the instructions in those emails. If you opt-out, we may still send you non-promotional emails, such as emails about our ongoing relationship.</p>
                            <h3>D. HOW WE USE INFORMATION</h3>
                            <p><strong>1)</strong> We use the information we collect primarily to provide, maintain, protect and improve our current products and services.</p>
                            <p><strong>2)</strong> We use the information collected through this website as described in this policy and we may use your information to:</p>
                            <p><strong>a.</strong> Improve our services, Site and how we operate our businesses or services;</p>
                            <p><strong>b.</strong> Understand and enhance your experience using our Site, products and services;</p>
                            <p><strong>c.</strong> Personalize our products or services and make recommendations on the reading;</p>
                            <p><strong>d.</strong> Provide and deliver products and services you request;</p>
                            <p><strong>e.</strong> Process, manage, complete, and account for transactions;</p>
                            <p><strong>f.</strong> Provide customer support and respond to your requests, comments, and inquiries;</p>
                            <p><strong>g.</strong> Send you related information, including confirmations, invoices, technical notices, updates, security alerts and support and administrative messages;</p>
                            <p><strong>h.</strong> Communicate with you about promotions, upcoming events and news about any products and services;</p>
                            <p><strong>i.</strong> We may process your personal information without your knowledge or consent where required by applicable law or regulation for the purposes of verification of identity or for prevention, detection or investigation, including of cyber incidents, prosecution and punishment of offences;</p>
                            <p><strong>j.</strong> Protect, investigate and deter against fraudulent, unauthorized or illegal activity.</p>
                            <p><strong>k.</strong> Any other use for the business deal</p>
                            <p><strong>3)</strong> By using this Website, you are agreeing that We may advertise your feedback on the Website and marketing materials.</p>
                            <p><strong>4)</strong> We may have to retain certain personal information of yours when necessary for legitimate business and / or if so and for such period as mandated by the concerned laws wherein legal purposes may include, but not limited to, such as security, fraud and abuse prevention, or record-keeping.</p>
                            <h3>E. DATA TRANSFER</h3>
                            <p><strong>1)</strong> Information about our user is an important part of our business and we take due care to protect the same.</p>
                            <p><strong>2)</strong> We may employ other companies and individuals to perform functions on our behalf. The functions include but not limited to sending postal mail and e-mail, removing repetitive information from customer lists, providing marketing assistance, providing search results and links (including paid listings and links), processing payments, transmitting content, and providing customer service.</p>
                            <p><strong>3)</strong> These third-party service providers have access to personal information needed to perform their functions but shall not use it for other purposes. Further, they must collect, store, process, manage the personal information in accordance with this Privacy Policy and as permitted by applicable data protection laws.</p>
                            <p><strong>4)</strong> Depending on the circumstances the disclosure of your personal information may involve transfer out of your jurisdiction;</p>
                            <p><strong>5)</strong> We release account and other personal information when we believe is appropriate to comply with the law, enforce or apply our conditions of use, and other agreements, protect the rights, property or safety of Us, our users or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction.</p>
                            <h3>F. DATA SECURITY</h3>
                            <p><strong>a.</strong> We take due care to protect customer data. Technical measures are in place to prevent unauthorized or unlawful access to data and against accidental loss or destruction of, or damage to, data.</p>
                            <p><strong>b.</strong> We work to protect the security of your information during transmission by using Secure Sockets Locker (SSL) software, which encrypts the information you input. SSL allows sensitive information such as credit card numbers, UID’s and login credentials to be transmitted securely.</p>
                            <p><strong>c</strong><strong>.</strong> We maintain electronic and procedural safeguards in connection with the collection, storage, and disclosure of personal customer information.</p>
                            <p><strong>d.</strong> We take reasonable steps to help protect your personal information in an effort to prevent the loss, misuse, and unauthorized access, disclosure alteration and destruction. It is your responsibility to protect your user names and passwords to help prevent anyone from accessing or abusing your accounts and services. You should not use or reuse the same passwords you use with other accounts as your password for our services.</p>
                            <p><strong>e.</strong> It is important for you to protect against unauthorized access to your password and your computers, devices, and applications. Be sure to sign off when you finish using a shared computer.</p>
                            <p><strong>f.</strong> Information you provide to us is shared on our secure servers. We have implemented appropriate technical and organizational measures designed to secure your information against accidental loss and unauthorized access, use, alteration or disclosure.</p>
                            <p><strong>g.</strong> Information collected from you will be stored for such period as required to complete the transaction entered into with you or such period as mandated under the applicable laws.</p>
                            <h3>G. LINKS TO THIRD PARTY SITE/APPS</h3>
                            <p>Our Site may, from time to time, contain links to and from other websites of third parties and / or reference of other sources of information of third parties. Please note that if you follow a link to any of these websites and / or other sources of information, such websites and sources will apply different terms to the collection and privacy of your personal data and we do not accept any responsibility or liability for these policies. When you leave our Site, we encourage you to read the privacy policy of every website you visit.</p>
                            <h3>H. SOCIAL NETWORK PLUGINS</h3>
                            <p>This Website incorporates plugins and/or buttons for social networks, in order to allow easy sharing on your favourite social networks. These plugins are programmed so as not to set any cookies when assessing the page to safeguard the privacy of users. Cookies may be set, if you make voluntary use of the plugin. The collection and use of information obtained by means of the plugin are governed by the respective privacy policies of the social networks and we will not be held responsible for misuse if it happens on your part.</p>
                            <h3>I. CHANGES TO THIS POLICY</h3>
                            <p>Our privacy policy is subject to change at any time without notice. To make sure you are aware of any changes, please review this policy periodically. At the beginning of the document, you can find the date on which our this policy was “Last Updated”. By visiting our website and your continued use of our services \ constitute your acceptance of this policy.</p>
                            <p><strong>J.</strong> In the event of any controversy or dispute between TVAM and you arising out of or in connection with your use of this website or under this privacy policy, the Parties shall attempt promptly and in good faith to resolve any such dispute. If both the Parties are unable to resolve the differences within a reasonable time, then either Party may take the matter before the court of law. You agree that all matters arising and relating to your access to use of our website including the terms and conditions of this policy and / or any disputes arising herewith or otherwise shall be governed by the laws of India without regards to the conflicts of laws provisions and the courts of Pune shall have exclusive jurisdiction and by visiting and use our website, services, and/or products you waive any objection to such jurisdiction and venue. Any claims under this Privacy Policy and/or under the terms and conditions of use must be brought within one (1) year from the date when such cause of action arises or such claim or cause of action is barred. Claims made under the separate terms and conditions of purchase for goods and services are not subject to limitation.</p>
                            <h3>K. NEWSLETTER</h3>
                            <p><strong>a.</strong> Following a subscription to the newsletter, your e-mail address is used for our advertising purposes until you cancel the newsletter again. Cancellation is possible at any time. The following consent has been expressly granted by you separately, or possibly in the course of an ordering process: (I am accepting to receive newsletter from this website), you may revoke your consent at any time with future effect. If you no longer want to receive the newsletter, then unsubscribe by clicking on unsubscribe option given in the email footer.<br />
                                If you have any concern about privacy or grievances with Us, please contact us with a thorough description and we will try to resolve the issue for you.</p>
                            <p><strong>Contact Details:</strong><br />
                                <a href="mailto:reachus@tvamfoundation.com">reachus@tvamfoundation.com</a></p>

                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default Privacy