import React, { Fragment, useEffect, useState } from "react";
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { Container } from 'react-bootstrap'
import LogoImage from '../assets/images/tvam-foundation-logo-b.png'
import { NavLink } from "react-router-dom";
const Header = () => {
    const [stickyClass, setStickyClass] = useState('relative');

    useEffect(() => {
        window.addEventListener('scroll', stickNavbar);

        return () => {
            window.removeEventListener('scroll', stickNavbar);
        };
    }, []);

    const stickNavbar = () => {
        if (window !== undefined) {
            let windowHeight = window.scrollY;
            windowHeight > 300 ? setStickyClass('fixedheader') : setStickyClass('relative');
        }
    };
    const [expanded, setExpanded] = useState(false);
    const engClickHandler=(e)=>{
        setExpanded(false);
        e.preventDefault();
        if(document.location.href.search('hn') != -1){
            document.location.href = document.location.href.replace('/hn/#/','/#/');
        }
        else if(document.location.href.search('mh') != -1){
            document.location.href = document.location.href.replace('/mh/#/','/#/');
        }
        else{
            document.location.href = document.location.href
        }
    }
    const hindiClickHandler=(e)=>{
        setExpanded(false);
        e.preventDefault();
        if(document.location.href.search('hn') != -1){
            document.location.href = document.location.href;
        }
        else if(document.location.href.search('mh') != -1){
            document.location.href = document.location.href.replace('/mh/#/','/hn/#/');
        }
        else{
            document.location.href = document.location.href.replace('/#/','/hn/#/');
        }
    }
    const marathiClickHandler=(e)=>{
        setExpanded(false);
        e.preventDefault();
        if(document.location.href.search('hn') != -1){
            document.location.href = document.location.href.replace('/hn/#/','/mh/#/');
        }
        else if(document.location.href.search('mh') != -1){
            document.location.href = document.location.href
        }
        else{
            document.location.href = document.location.href.replace('/#/','/mh/#/');
        }
    }

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/payment-button.js';
        script.setAttribute('data-payment_button_id', 'pl_JwK8ZqJglOChCV');
        script.async = true;
        document.getElementById('yahoo').appendChild(script);
        
    },
    []);
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/payment-button.js';
        script.setAttribute('data-payment_button_id', 'pl_JwK8ZqJglOChCV');
        script.async = true;
        document.getElementById('yahoo1').appendChild(script);
        
    },
    []);


    return (
        <Fragment>
            <Navbar collapseOnSelect bg="light" expand="lg" className={`regularMnu    ${stickyClass}`} expanded={expanded}>
                <Container>
                    <Navbar.Brand href="#home"><img src={LogoImage} className='logoMenu' />
                    <p className="logotext" style={{color:"#000"}}> The Vishwas & Anuradha Memorial Foundation</p>
                    </Navbar.Brand>
                       <div className="desktopHide">
                    <form id="yahoo1"  style={{marginRight:'6px'}}></form>
                    </div>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")}/>
                    <Navbar.Collapse id="responsive-navbar-nav">
                    
                        <Nav className="me-auto">
 
                        </Nav>
                        
                        <Nav>
                            {/* <NavLink to="/home" className="nav-link" onClick={() => setExpanded(false)}>HOME</NavLink> */}
                            <NavDropdown title="ABOUT US" id="collasible-nav-dropdown">
                                <NavLink to="/about-us"     className="nav-link" onClick={() => setExpanded(false)}>THE FOUNDATION</NavLink>
                                <NavLink to="/library-at-tvam" className="nav-link" onClick={() => setExpanded(false)}>LIBRARY AT TVAM</NavLink>
                                <NavLink to="/in-the-press" className="nav-link" onClick={() => setExpanded(false)}>IN THE PRESS</NavLink>
                                <NavLink to="/contact-us" className="nav-link" onClick={() => setExpanded(false)} >CONTACT US</NavLink>
                            </NavDropdown>

                            <NavDropdown title="INITIATIVES" id="collasible-nav-dropdown">
                            <NavLink to="/initiatives" className="nav-link" onClick={() => setExpanded(false)}>INITIATIVES</NavLink>
                                <NavLink to="/kath-padar-paithani-and-beyond" className="nav-link" onClick={() => setExpanded(false)}>KATH PADAR: PAITHANI & BEYOND</NavLink>
                            </NavDropdown>


                            
                            <NavDropdown title="PUBLICATIONS" id="collasible-nav-dropdown">
                            <NavLink to="/publication" className="nav-link" onClick={() => setExpanded(false)}>Publishing by TVAM</NavLink>
                                <NavLink to="/epiphanies-in-Indian-Fashion" className="nav-link" onClick={() => setExpanded(false)}>EPIPHANIES IN INDIAN FASHION</NavLink>
                                <NavLink to="/kath-padar-catalogue" className="nav-link" onClick={() => setExpanded(false)}>KATH PADAR - CATALOGUE</NavLink> 
                            </NavDropdown>
                            {/* <NavLink to="/in-the-press" className="nav-link" onClick={() => setExpanded(false)}>IN THE PRESS</NavLink> */}
                            <a href="https://tvamfoundation.com/blog/" target="_blank" rel="noreferrer" className="nav-link">BLOG</a>
                            <NavLink to="/discover-with-TVAM" className="nav-link" onClick={() => setExpanded(false)}>Discover with TVAM</NavLink>

                         
                            {/* <NavLink to="/about-us" className="nav-link" onClick={() => setExpanded(false)}>ABOUT US</NavLink> */}
                          
                            <NavDropdown title="LANGUAGE" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="#" onClick={engClickHandler}>English</NavDropdown.Item>
                                <NavDropdown.Item href="#" onClick={hindiClickHandler}>Hindi</NavDropdown.Item>
                                <NavDropdown.Item href="#" onClick={marathiClickHandler}>Marathi</NavDropdown.Item>
                            </NavDropdown>
                            {/*<NavLink to="/event" className="nav-link" onClick={() => setExpanded(false)}>EVENT</NavLink>*/}
                        </Nav>
                        
                    </Navbar.Collapse>
                   
                </Container>
                
                <div className="mobileHide">
                    <form id="yahoo"  style={{marginRight: '20px'}}></form>
                    </div>

            </Navbar>
        </Fragment>
    )
}
export default Header