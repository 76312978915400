import React, { Fragment, useEffect } from "react";
import BannerImage from '../../assets/images/about-header.jpg'
import About1Image from '../../assets/images/about1.jpg'
import About2Image from '../../assets/images/about2.png'
import RasikaImage from '../../assets/images/Rasika.png'
import ChetanImage from '../../assets/images/Chetan.png'
import AlokImage from '../../assets/images/Alok.png'
import RadhikaRajeImage from '../../assets/images/RadhikaRaje.png'
import RahulImage from '../../assets/images/rahul-jain.png'
import MayankImage from '../../assets/images/mayank.png'

import prajaktaImage from '../../assets/images/prajakta.jpg'
import amoghImage from '../../assets/images/amogh.jpg'
import anuragImage from '../../assets/images/anurag.jpg'
import rutujaImage from '../../assets/images/rutuja.jpg'
import tulikaImage from '../../assets/images/tulika.jpg'

import LogoImage from '../../assets/images/tvam-foundation-logo-b.png'
import { Helmet } from 'react-helmet';
const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <Fragment>
             <Helmet>
         <title>About Us | TVAM Foundation</title>
      <meta name="description" content="TVAM Foundation is committed to researching, celebrating, and informing the general public of the rich textile traditions of the Deccan and its narratives. "/>

         </Helmet>
            <img src={BannerImage} className='HomeBannerImg' />
            <div className="container">
                <div className="row customSpacing3">
                    <h2 className="text-center display2 marginBottom24px">WHO WE ARE</h2>
                    <h2 className="text-center innerSubHeading marginBottom24px">The Story of TVAM Foundation</h2>
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        
                        <div className="row">
                            <div className="col-md-5">
                                <p className="paraStyle"><strong>The Vishwas &amp; Anuradha Memorial (TVAM) Foundation</strong> was founded by Rasika Wakalkar in the memory of her late parents, Anuradha and Vishwas Mhalgi, in Mar 2019.</p>
                                <p className="paraStyle">The Foundation is committed to researching, celebrating and informing the general public of the rich textile traditions of the Deccan, and its narratives.<br /><br />For revival to happen, one needs authentic research in place. We facilitate and enable our ecosystems.</p>
                            </div>
                            <div className="col-md-7">
                                <img src={About1Image} className='HomeBannerImg' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bgLightGrey">
                <div className="container">
                    <div className="row customSpacing3">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-5">
                                    <img src={LogoImage} className='aboutLogo' />
                                </div>
                                <div className="col-md-7">
                                    <p className="paraStyle">Abbreviated, TVAM translates to ‘you‘ in Sanskrit and it's a coincidence we have embraced. This cultural journey is, after all, incomplete without you. </p>
                                    <p className="paraStyle">The two bars above and below the logo may look containing or restricting at first glance, but they are our interpretation of a flowing river within banks. Occasionally, you have to sidestep the flow of the river, walk on its banks and search for its source to trace meanings and analogical nuances between cultural markers of textiles, art, architecture, heritage and history thereof.</p>
                                    <p className="paraStyle">Most civilisations across the world have flourished along the banks of a river; a beautiful meaning for a foundation to take inspiration from.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row customSpacing3">
                    <h2 className="text-center display2 marginBottom24px">THE GENESIS OF TVAM FOUNDATION</h2>
                    <h2 className="text-center innerSubHeading marginBottom24px">The learnings over ten years of being on the field exposed the gaps, which drove Rasika to launch TVAM Foundation in the hope to make a difference and impact to the field.</h2>
                    <div className="col-md-12 mobileHide">
                        <div className="container py-2 mt-4 mb-4">
                            <div className="row no-gutters">
                                <div className="col-sm  py-2">
                                    <div className="card timeLineBlock">
                                        <div className="card-body">
                                            <h4 className="card-title timelineTitle">RUDRAKSH</h4>
                                            <p className="card-text">Rudraksh, a multi-designer store as a retail venture was launched by Rasika & Chetan Wakalkar to celebrate and encourage Indian design talent.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-1 text-center flex-column d-none d-sm-flex">
                                    <div className="row h-50">
                                        <div className="col">&nbsp;</div>
                                        <div className="col">&nbsp;</div>
                                    </div>
                                    <h5 className="m-2">
                                        <span className="badge badge-pill borderCircle">&nbsp;</span>
                                    </h5>
                                    <div className="row h-50">
                                        <div className="col border-right">&nbsp;</div>
                                        <div className="col">&nbsp;</div>
                                    </div>
                                </div>
                                <div className="col-sm py-2">
                                    <div className="card timeLineBlock dateBlock">
                                        <div className="card-body">
                                            <h4 className="card-title timelineTitle">2008</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row no-gutters">
                                <div className="col-sm py-2">
                                    <div className="card timeLineBlock dateBlock floatRight">
                                        <div className="card-body">
                                            <h4 className="card-title timelineTitle">2015-17</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-1 text-center flex-column d-none d-sm-flex">
                                    <div className="row h-50">
                                        <div className="col border-right">&nbsp;</div>
                                        <div className="col">&nbsp;</div>
                                    </div>
                                    <h5 className="m-2">
                                        <span className="badge badge-pill borderCircle">&nbsp;</span>
                                    </h5>
                                    <div className="row h-50">
                                        <div className="col border-right">&nbsp;</div>
                                        <div className="col">&nbsp;</div>
                                    </div>
                                </div>
                                <div className="col-sm py-2">
                                    <div className="card timeLineBlock">
                                        <div className="card-body">
                                            <h4 className="card-title timelineTitle">THE FASHION NARRATIVE</h4>
                                            <p className="card-text">The Fashion Circle 2015 and The Fashion Narrative 2017 were born out of the observations from the Rudraksh experience as conceptualised by Rasika. The events engaged more than 1000 people in a format that combined workshops, content sharing and retail.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row no-gutters">
                                <div className="col-sm py-2">
                                    <div className="card timeLineBlock">
                                        <div className="card-body">
                                            <h4 className="card-title timelineTitle">TVAM FOUNDATION</h4>
                                            <p className="card-text"> 2019 was a landmark year in the journey, and we aim to continue in this spirit of the vision, mission, and purpose defined to make this vision a reality at the highest levels of benchmark in mapping the fashion and textile histories of the Deccan.
TVAM Foundation is a not-for-profit organisation.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-1 text-center flex-column d-none d-sm-flex">
                                    <div className="row h-50">
                                        <div className="col border-right">&nbsp;</div>
                                        <div className="col">&nbsp;</div>
                                    </div>
                                    <h5 className="m-2">
                                        <span className="badge badge-pill borderCircle">&nbsp;</span>
                                    </h5>
                                    <div className="row h-50">
                                        <div className="col">&nbsp;</div>
                                        <div className="col">&nbsp;</div>
                                    </div>
                                </div>
                                <div className="col-sm py-2">
                                    <div className="card timeLineBlock dateBlock">
                                        <div className="card-body">
                                            <h4 className="card-title timelineTitle">2019</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-md-12 desktopHide">
                        <div className="container py-2 mt-4 mb-4">
                            <div className="row no-gutters">
                                <div className="col-sm py-2">
                                    <div className="card timeLineBlock dateBlock">
                                        <div className="card-body">
                                            <h4 className="card-title timelineTitle">2008</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm  py-2">
                                    <div className="card timeLineBlock">
                                        <div className="card-body">
                                            <h4 className="card-title timelineTitle">RUDRAKSH</h4>
                                            <p className="card-text">Rudraksh, a multi-designer store was launched by Rasika & Chetan Wakalkar to celebrate and encourage Indian design talent.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row no-gutters">
                                <div className="col-sm py-2">
                                    <div className="card timeLineBlock dateBlock">
                                        <div className="card-body">
                                            <h4 className="card-title timelineTitle">2015-17</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm py-2">
                                    <div className="card timeLineBlock">
                                        <div className="card-body">
                                            <h4 className="card-title timelineTitle">THE FASHION NARRATIVE</h4>
                                            <p className="card-text">The Fashion Circle 2015 and The Fashion Narrative 2017 were born out of the observations from the Rudraksh experience as conceptualised by Rasika. The events engaged more than 1000 people in a format that combined workshops, content sharing and retail.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row no-gutters">
                                <div className="col-sm py-2">
                                    <div className="card timeLineBlock dateBlock">
                                        <div className="card-body">
                                            <h4 className="card-title timelineTitle">2019</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm py-2">
                                    <div className="card timeLineBlock">
                                        <div className="card-body">
                                            <h4 className="card-title timelineTitle">TVAM FOUNDATION</h4>
                                            <p className="card-text">2019 was a landmark year in the journey, and we aim to continue in this spirit of the vision, mission, and purpose defined to make this vision a reality at the highest levels of benchmark in mapping the fashion and textile histories of the Deccan. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="bgLightGrey">
                <div className="container">
                    <div className="row customSpacing3">
                        <div className="col-md-7">
                            <h2 className="display2 marginBottom24px">OUR MISSION</h2>
                            <p className="paraStyle greyColorText">To preserve & document the intangible knowledge that weavers possess and a textile’s historic & cultural evolution, <b>we commission research and documentation of textile narratives</b> of Deccan India. </p>
                            <p className="paraStyle greyColorText">To <b>create awareness, knowledge share & patronage of textiles</b> through curatorial & publishing projects, digital & social media, workshops & more. </p>
                            <p className="paraStyle greyColorText">To <b>support skilling & livelihood opportunities</b> for weavers to bring back some of the lost glory of the textile. To inspire highest qualities of woven cloth possible.</p>
                        </div>
                        <div className="col-md-5">
                            <img src={About2Image} className='HomeBannerImg' />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row customSpacing3">
                    <h2 className="text-center display2 marginBottom24px">PRINCIPAL TRUSTEES</h2>
                    <div className="col-md-3">
                        <img src={RasikaImage} className='HomeBannerImg' />
                        <div className="text-center">
                            <p className="paraStyle2">Rasika Mhalgi Wakalkar</p>
                            <p>MANAGING  TRUSTEE</p>
                            <br />
                            <p>Conceived the vision & purpose of this not-for-profit Foundation. Her experience of over fourteen years in the Indian fashion industry brought forth insights which became the bedrock of the Foundation’s goals and purpose.</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <img src={ChetanImage} className='HomeBannerImg' />
                        <div className="text-center">
                            <p className="paraStyle2">Chetan Wakalkar</p>
                            <p>TRUSTEE</p>
                            <br />
                            <p>A well-known educationist in Pune, he is the Group Director of the Indira Group of Institutes, Pune. </p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <img src={AlokImage} className='HomeBannerImg' />
                        <div className="text-center">
                            <p className="paraStyle2">Dr Alok Patel</p>
                            <p>TRUSTEE</p>
                            <br />
                            <p>Dr. Patel leads a private dental practice and spearheads pioneering work for an autism project funded by the Tata Group.</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <img src={RadhikaRajeImage} className='HomeBannerImg' />
                        <div className="text-center">
                            <p className="paraStyle2">RadhikaRaje Gaekwad</p>
                            <p>GOODWILL PATRON</p>
                            <br />
                            <p>The Maharani of Baroda, she is a heritage conservationist and textile revivalist. </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bgLightGrey">
                <div className="container">
                    <div className="row customSpacing3">
                        <h2 className="text-center display2 marginBottom24px">ADVISORS TO THE FOUNDATION</h2>
                        <h2 className="text-center innerSubHeading marginBottom24px">Our consultants and advisors have helped us in establishing the Foundation’s mandates and projects to bring projects and publications to fruition.</h2>
                        <div className="col-md-1"></div>
                        <div className="col-md-10 boxStyle">
                            <div className="row">
                                <div className="col-md-2">
                                    <img src={RahulImage} className='HomeBannerImg' />
                                </div>
                                <div className="col-md-10">
                                    <p className="paraStyle"><b>Rahul Jain</b> is a textile specialist and historian who lives and works in New Delhi. He has written and published extensively on historical and contemporary Indian textiles, with over 19 publications and writings to his credit. A Jameel Prize 3 finalist, he received the Padma Shree from the Government of India in 2015.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-10 boxStyle">
                            <div className="row">
                                <div className="col-md-2">
                                    <img src={MayankImage} className='HomeBannerImg' />
                                </div>
                                <div className="col-md-10">
                                    <p className="paraStyle"><b>Mayank Mansingh Kaul</b> is a Delhi-based writer and curator, with an interest in post-independence histories of design, fashion and textiles in India. Exhibitions that he has curated include The Idea of Fashion, Gold: The Art of Zari (Bikaner House, New Delhi), 25 Years of Abraham & Thakore (New Delhi), Crossroads: Textile Journeys with Ritu Kumar, and Meanings and Metaphors: The Registry of Sarees. He is also the editor of Take on Art Design, Cloth and India: Towards Recent Histories, 1947-2015 (Marg), Baluchari: Tradition and Beyond an upcoming publication on the last century of Indian fashion for Take.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row customSpacing3">
                    <h2 className="text-center display2 marginBottom24px">TVAM TEAM</h2>
                    <div className="col-md-2 teamBox">
                        <img src={prajaktaImage} className='HomeBannerImg' />
                        <div className="text-center">
                            <p className="paraStyle2"><b>Prajakta Degaonkar</b></p>
                            <p>Intern </p>
                            <br />
                        </div>
                    </div>
                    <div className="col-md-2 teamBox">
                        <img src={anuragImage} className='HomeBannerImg' />
                        <div className="text-center">
                            <p className="paraStyle2"><b>Anurag Vaidya </b></p>
                            <p>Researcher, Author, and Historian </p>
                            <br />
                        </div>
                    </div>
                    <div className="col-md-2  teamBox">
                        <img src={amoghImage} className='HomeBannerImg' />
                        <div className="text-center">
                            <p className="paraStyle2"><b>Amogh Vaidya </b></p>
                            <p>Researcher, Sanskrit and Indology </p>
                            <br />
                        </div>
                    </div>
                    <div className="col-md-2 teamBox">
                        <img src={rutujaImage} className='HomeBannerImg' />
                        <div className="text-center">
                            <p className="paraStyle2"><b>Rutuja Sahasrabudhe </b></p>
                            <p> Project Associate and Researcher </p>
                            <br />
                        </div>
                    </div>
                    
                    <div className="col-md-2 teamBox">
                        <img src={tulikaImage} className='HomeBannerImg' />
                        <div className="text-center">
                            <p className="paraStyle2"><b>Tulika Nair </b></p>
                            <p> Social media and Communication Lead </p>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default About